import * as qs from 'qs';
import { AuthenticationResponse } from '../models/authenticationResponse';
import { closeBroadcastChannel } from './broadcastChannel';

export const handleOpener = (authenticationResponse: AuthenticationResponse) => {
  closeBroadcastChannel();
  const query = qs.parse(location.search.substr(1));
  const popupFlow = query.flow === 'popup';

  if (popupFlow && opener) {
    const loginSuccessMessage = {
      name: 'login-success',
      token: authenticationResponse.token,
      code: authenticationResponse.code,
    };

    opener.postMessage(loginSuccessMessage, '*');
  }

  if (popupFlow) {
    window.close();
    return true;
  }

  return false;
};
