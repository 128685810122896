import { handleActions } from 'redux-actions';
import * as Actions from '../actions/changePasswordActions';

const initialState = {
  initialized: false,
  inProgress: false,
  success: false,
  locale: null,
  changePasswordData: {
    email: null,
    newEmail: null,
    changeEmailFlow: null,
    phoneNumber: '',
  },
  secret: '',
  password: '',
  errorCode: null,
  secondFactorToken: null,
};

export const changePasswordReducer = handleActions<any>(
  {
    [Actions.setInitialized]: (state, action) => {
      return { ...state, initialized: action.payload };
    },
    [Actions.setInProgress]: (state, action) => {
      return { ...state, inProgress: action.payload };
    },
    [Actions.setChangePasswordData]: (state, action) => {
      return { ...state, changePasswordData: action.payload };
    },
    [Actions.setLocale]: (state, action) => {
      return { ...state, locale: action.payload };
    },
    [Actions.setSecret]: (state, action) => {
      return { ...state, secret: action.payload };
    },
    [Actions.setPassword]: (state, action) => {
      return { ...state, password: action.payload };
    },
    [Actions.setSuccess]: (state, action) => {
      return { ...state, success: action.payload };
    },
    [Actions.setErrorCode]: (state, action) => {
      return { ...state, errorCode: action.payload };
    },
    [Actions.setSecondFactorToken]: (state, action) => {
      return { ...state, secondFactorToken: action.payload };
    },
  },
  initialState,
);
