import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/ssoLoginActions';
import * as secondFactorVerificationActions from '../../actions/secondFactorVerification';
import * as trackingActions from '../../actions/tracking';
import { IdTokenResult, SAMLAuthProvider, UserCredential } from 'firebase/auth';
import firebase from '../../firebase';
import { AuthenticationError } from '../../models/authenticationError';
import * as api from './api';
import { logError } from '../error';
import commonApi from '../../sagas/commonApi';
import { handleOpener } from '../../utils/handleOpener';
import {
  setBusinessUnitCookie,
  setIsSsoAuthenticatedCookie,
  setSsoAuthenticatedBusinessAccountCookie,
  setSsoRecentlyAuthenticatedCookie,
} from '../../utils/cookies';
import { SsoConfigurationResponse } from 'app/models/ssoConfigurationResponse';
import { AuthenticationResponse } from 'app/models/authenticationResponse';

function getResponseType(): string {
  const responseType = window.location.search.match(/response_type=([^&|?]+)/);

  if (responseType && responseType.length > 1) {
    return responseType[1];
  }

  return '';
}

function* login() {
  let ssoConfiguration: SsoConfigurationResponse | undefined;
  let userCredential: UserCredential | undefined;

  try {
    const domain = yield select((state) => state.ssoLogin.domain);
    const query = window.location.search.substr(1);
    ssoConfiguration = yield call(api.getSsoConfiguration, domain);

    try {
      if (!ssoConfiguration) {
        yield put(actions.setFailed(AuthenticationError.SSO_CONFIGURATION_NOT_FOUND));
        return;
      }

      const provider = new SAMLAuthProvider(ssoConfiguration.providerId);

      userCredential = yield call(firebase.signInWithSaml, provider);
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = firebase.getMultiFactorResolver(error);
        const {
          customData: {
            _serverResponse: { email, mfaInfo },
          },
        } = error;

        yield put(secondFactorVerificationActions.initialize(resolver, email, query, mfaInfo[0].phoneInfo));

        // above yeild will error if 2FA fails, therefore we only get here if it is a success

        const businessUnitId: string = yield call(api.getUnitIdForDomainName, domain);
        const businessAccountId = ssoConfiguration.businessAccountId;

        setBusinessUnitCookie(businessUnitId, window.location);
        setIsSsoAuthenticatedCookie(true, window.location);
        setSsoAuthenticatedBusinessAccountCookie(businessAccountId, window.location);
        setSsoRecentlyAuthenticatedCookie(true, window.location);

        return;
      } else if (error.code === 'auth/user-disabled') {
        yield put(actions.setFailed(AuthenticationError.INACTIVE_USER));
        return;
      } else if (error.code === 'auth/user-not-found') {
        yield put(actions.setFailed(AuthenticationError.USER_NOT_FOUND));
        return;
      } else if (error.code === 'auth/popup-closed-by-user') {
        return;
      }

      if (error.response?.status === 400) {
        yield put(actions.setFailed(AuthenticationError.SSO_CONFIGURATION_NOT_FOUND));
        return;
      }

      throw error;
    }

    const firebaseUser = userCredential?.user;
    const responseType = getResponseType();

    if (firebaseUser) {
      const idTokenResult: IdTokenResult = yield call(firebase.getCurrentSamlUserIdTokenResult);
      const idToken = idTokenResult.token;
      const authenticationResponse: AuthenticationResponse = yield call(
        commonApi.ssoAuthentication,
        idToken,
        firebaseUser.email,
        userCredential.providerId,
      );

      if (authenticationResponse.isValid) {
        const businessUnitId: string = yield call(api.getUnitIdForDomainName, domain);
        const businessAccountId = ssoConfiguration.businessAccountId;

        setBusinessUnitCookie(businessUnitId, window.location);
        setIsSsoAuthenticatedCookie(true, window.location);
        setSsoAuthenticatedBusinessAccountCookie(businessAccountId, window.location);
        setSsoRecentlyAuthenticatedCookie(true, window.location);

        if (handleOpener(authenticationResponse)) {
          return;
        }

        yield put(
          trackingActions.trackLoginAttempt({
            email: firebaseUser.email,
            responseType,
            provider: trackingActions.Providers.SSO,
            loginSuccess: true,
            redirectUrl: authenticationResponse.redirectUrl,
            providerId: ssoConfiguration.providerId,
          }),
        );
      } else {
        yield put(
          trackingActions.trackLoginAttempt({
            email: firebaseUser.email,
            responseType,
            provider: trackingActions.Providers.SSO,
            loginSuccess: false,
            providerId: ssoConfiguration.providerId,
          }),
        );

        yield put(actions.setFailed(authenticationResponse.authenticationError));
      }
    }
  } catch (error) {
    yield put(actions.setFailed(AuthenticationError.UNEXPECTED_ERROR));
    yield call(logError, error);

    yield put(
      trackingActions.trackLoginAttempt({
        provider: trackingActions.Providers.SSO,
        loginSuccess: false,
        providerId: ssoConfiguration?.providerId,
      }),
    );
  }
}

export default function* () {
  yield all([takeLatest(matchesType(actions.login), login)]);
}
