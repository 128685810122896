import { handleActions } from 'redux-actions';
import * as Actions from '../actions/loginForm';

const initialState = {
  authenticationErrorCode: null,
  authenticationErrorMessage: null,
  googleAuthenticateInProgress: false,
  authenticateInProgress: false,
  password: '',
  email: '',
  googleAuthenticationEnabled: true,
  locale: null,
  linkSent: false,
  connectionId: null,
};

export const loginFormReducer = handleActions<any>(
  {
    [Actions.setAuthenticationErrorCode]: (state, action) => {
      return { ...state, authenticationErrorCode: action.payload };
    },
    [Actions.setAuthenticationErrorMessage]: (state, action) => {
      return { ...state, authenticationErrorMessage: action.payload };
    },
    [Actions.setGoogleAuthenticateInProgress]: (state, action) => {
      return { ...state, googleAuthenticateInProgress: action.payload };
    },
    [Actions.setAuthenticateInProgress]: (state, action) => {
      return {
        ...state,
        authenticateInProgress: action.payload,
        authenticationErrorMessage: null,
        authenticationErrorCode: null,
      };
    },
    [Actions.setPassword]: (state, action) => {
      return { ...state, password: action.payload };
    },
    [Actions.setEmail]: (state, action) => {
      return { ...state, email: action.payload };
    },
    [Actions.setGoogleAuthenticationEnabled]: (state, action) => {
      return { ...state, googleAuthenticationEnabled: action.payload };
    },
    [Actions.linkSent]: (state) => {
      return { ...state, linkSent: true };
    },
    [Actions.setConnectionId]: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  initialState,
);
