import * as actions from '../../../actions/loginLink';
import { connect, ConnectedProps } from 'react-redux';
import { LinkLogin } from './linkLogin';

const mapDispatchToProps = {
  authenticateLink: actions.authenticateLink,
};

const mapStateToProps = (state) => ({
  emailRequired: state.loginLink.emailRequired,
  authenticationStep: state.login.authenticationStep,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LinkLoginProps = ConnectedProps<typeof connector>;

const LinkLoginContainer = connector(LinkLogin);

export default LinkLoginContainer;
