import * as actions from '../../../actions/login';
import { connect, ConnectedProps } from 'react-redux';
import { Login } from './login';

const mapDispatchToProps = {
  initialize: actions.initialize,
};

const mapStateToProps = (state) => ({
  initialized: state.login.initialized,
  authenticationStep: state.login.authenticationStep,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LoginProps = ConnectedProps<typeof connector>;

const LoginContainer = connector(Login);

export default LoginContainer;
