import React from 'react';
import { StyledCard, StyledHeader } from '../../components/commonElements';
import { Text, useTranslations } from 'ilenia';
import styled from '@emotion/styled';
import { escapedHtml } from '../../utils/escapedHtml';
import BackToLoginLink from '../../components/back-to-login-link/backToLoginLink';

const LostDeviceSuccess: React.FC<{ email: string }> = ({ email }) => {
  const [translations] = useTranslations();
  const informationString = translations['lostDeviceSuccess.information'].replace('[EMAIL]', escapedHtml(email));

  return (
    <StyledCard>
      <StyledHeader>
        <Text id="lostDeviceSuccess.header" />
      </StyledHeader>
      <StyledInformationText>
        <span
          dangerouslySetInnerHTML={{
            __html: informationString,
          }}
         />
      </StyledInformationText>
      <BackToLoginLink onClick={() => window.location.reload()} />
    </StyledCard>
  );
};

const StyledInformationText = styled.div({
  marginBottom: 32,
  fontSize: 16,
  lineHeight: '24px',
});

export default LostDeviceSuccess;
