import { getTranslations } from './localization/translations';
import { LocalizationProvider } from 'ilenia';
import * as React from 'react';
import { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { Routes } from './models/routes';
import { GlobalAlert, GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import { css, Global } from '@emotion/core';
import '@trustpilot/icons/dist/styles.css';
import * as app from './actions/app';
import { connect, ConnectedProps } from 'react-redux';
import ActivateAccountContainer from './components/pages/activate-account/activateAccountContainer';
import ForgotPasswordContainer from './components/pages/forgot-password/forgotPasswordContainer';
import ChangePasswordContainer from './components/pages/change-password/changePasswordContainer';
import LoginContainer from './components/pages/login/loginContainer';
import ResetLostDeviceContainer from './components/pages/reset-lost-device/resetLostDeviceContainer';
import { DoNotSellMyInfoContainer } from './components/pages/do-not-sell-my-info/doNotSellMyInfo';
import SsoLoginContainer from './components/pages/sso-login/ssoLoginContainer';
import LinkLoginContainer from './components/pages/link-login/linkLoginContainer';
import styled from '@emotion/styled';
import './styles/styles.scss';

const App: React.FC<AppProps> = ({ initialize, initialized, locale }) => {
  useEffect(() => {
    if (!initialized) {
      initialize();
    }
  }, [initialized]);

  if (!initialized) {
    return null;
  }

  return (
    <StyledAppContainer>
      <LocalizationProvider locale={locale} translations={getTranslations(locale)}>
        <GlobalAlert subscribe={GlobalAlertService.subscribe} unsubscribe={GlobalAlertService.unsubscribe} />
        <Global styles={GlobalStyles} />
        <Switch>
          <Route path={Routes.ACTIVATE_ACCOUNT} component={ActivateAccountContainer} />
          <Route path={Routes.CHANGE_PASSWORD} component={ChangePasswordContainer} />
          <Route path={Routes.FORGOT_PASSWORD} component={ForgotPasswordContainer} />
          <Route path={Routes.RESET_LOST_DEVICE} component={ResetLostDeviceContainer} />
          <Route path={Routes.DO_NOT_SELL_MY_INFO} component={DoNotSellMyInfoContainer} />
          <Route path={Routes.SSO_LOGIN} component={SsoLoginContainer} />
          <Route path={Routes.LINK_LOGIN} component={LinkLoginContainer} />
          <Route path={Routes.LOGIN} component={LoginContainer} exact />
        </Switch>
      </LocalizationProvider>
    </StyledAppContainer>
  );
};

const StyledAppContainer = styled.div({
  backgroundColor: 'var(--elevation-background)',
  height: '100%',
});

const GlobalStyles = css({
  html: {
    height: '100%',
  },
  body: {
    padding: 0,
    margin: 0,
    height: '100%',
    fontFamily: 'TrustpilotDefaultFont',
  },
  '#root': {
    height: '100%',
    '> *': {
      height: '100%',
    },
  },
  '#webpack-dev-server-client-overlay': {
    display: 'none',
  },
});

const mapStateToProps = (state) => ({
  initialized: state.app.initialized,
  locale: state.app.locale,
});

const mapDispatchToProps = {
  initialize: app.initialize,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AppProps = ConnectedProps<typeof connector>;

export default withRouter(connector(App));
