import tracking from './tracking/tracking';
import activateAccount from './activateAccount/activateAccount';
import changePassword from './change-password/changePassword';
import forgotPassword from './forgotPassword/forgotPassword';
import login from './login/login';
import loginForm from './login-form/loginForm';
import secondFactorVerification from './second-factor-verification/secondFactorVerification';
import lostDevice from './lost-device/lostDevice';
import resetLostDevice from './reset-lost-device/resetLostDevice';
import app from './app/app';
import changePasswordSecondFactorForm from './change-password-second-factor-form/changePasswordSecondFactorForm';
import ssoLogin from './sso-login/ssoLogin';
import loginLink from './login-link/loginLink';

export default [
  tracking,
  activateAccount,
  changePassword,
  forgotPassword,
  login,
  loginForm,
  app,
  secondFactorVerification,
  lostDevice,
  resetLostDevice,
  changePasswordSecondFactorForm,
  ssoLogin,
  loginLink,
];
