import { HtmlText, Text, useTranslations } from 'ilenia';
import {
  BannerAlert,
  Button,
  Card,
  Icon,
  Input,
  Link,
  PageLoader,
  Typography,
} from '@trustpilot/businessapp-patternlibrary';
import { GoogleIcon } from '../../components/google-icon/googleIcon';
import * as React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { AuthenticationErrorMessage } from '../../models/authenticationError';
import Loading from '../../components/loading';

import { StyledHeader, StyledInputFieldWrapper } from '../commonElements';
import { Routes } from '../../models/routes';
import { LoginProps } from '../../components/login-form/loginFormContainer';
import configuration from '../../configuration';
import { key_16 as KeyIcon } from '@trustpilot/icons';
import {
  setIsSsoAuthenticatedCookie,
  setSsoAuthenticatedBusinessAccountCookie,
  setSsoRecentlyAuthenticatedCookie,
} from '../../utils/cookies';
import { AuthenticationStep } from '../../models/authenticationStep';
import { connect } from '../../utils/connectionSocket';

export const LoginForm: React.FC<LoginProps> = ({
  authenticateWithGoogle,
  signInWithGoogle,
  authenticationErrorCode,
  authenticationErrorMessage,
  googleAuthenticateInProgress,
  authenticateInProgress,
  authenticate,
  setEmail,
  setPassword,
  email,
  password,
  googleAuthenticationEnabled,
  onClickSignup,
  pendingRedirect,
  customTokenPassed,
  linkSent,
  authenticationStep,
  emailEntered,
  setAuthenticationStep,
  setConnectionId,
  completeLogin,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [translations] = useTranslations();
  const authenticationErrorTranslated = AuthenticationErrorMessage[authenticationErrorCode];
  const ssoAuthenticationEnabled = configuration.EnableSSOAuthentication === 'true';
  // Reset sso cookies before log in
  setIsSsoAuthenticatedCookie(false, window.location);
  setSsoAuthenticatedBusinessAccountCookie('', window.location);
  setSsoRecentlyAuthenticatedCookie(false, window.location);

  React.useEffect(() => {
    const authenticationResponseReceived = (event) => {
      const search = window.location.search;
      const query = search.substr(1);

      completeLogin(query, event.detail);
    };

    if (window.opener) {
      connect().then(setConnectionId);
    }

    window.addEventListener('authentication-response', authenticationResponseReceived);

    return () => {
      window.removeEventListener('authentication-response', authenticationResponseReceived);
    };
  }, [setConnectionId, completeLogin]);

  const handleAuthenticateWithGoogle = () => {
    if ((window as any).safari) {
      signInWithGoogle();
    } else {
      authenticateWithGoogle();
    }
  };

  const handleAuthenticate = () => {
    switch (true) {
      case Boolean(authenticationStep === AuthenticationStep.EMAIL_ENTRY && email):
        emailEntered(email);
        break;
      case Boolean(authenticationStep === AuthenticationStep.LOGIN && email && password):
        authenticate();
        break;
    }
  };

  const handleEmailChange = (updatedEmail: string) => {
    setEmail(updatedEmail);
    const isTestUser = updatedEmail.endsWith('@tp-automatedtesting.com');
    if (isTestUser && authenticationStep !== AuthenticationStep.LOGIN) {
      setAuthenticationStep(AuthenticationStep.LOGIN);
    } else if (!isTestUser && authenticationStep !== AuthenticationStep.EMAIL_ENTRY) {
      setAuthenticationStep(AuthenticationStep.EMAIL_ENTRY);
    }
  };

  const inProgress = googleAuthenticateInProgress || authenticateInProgress || pendingRedirect;
  const noError = !authenticationErrorCode && !authenticationErrorMessage && !authenticationErrorTranslated;

  if (customTokenPassed && noError) {
    return (
      <Card>
        <CenteredContainer>
          <PageLoader />
        </CenteredContainer>
      </Card>
    );
  }

  const loginDisabled =
    inProgress ||
    (authenticationStep === AuthenticationStep.EMAIL_ENTRY && !email) ||
    (authenticationStep !== AuthenticationStep.EMAIL_ENTRY && (!email || !password));

  return (
    <Card>
      <StyledHeader>
        <Text id="login.header" />
      </StyledHeader>
      {(authenticationErrorTranslated || authenticationErrorMessage) && (
        <StyledBannerAlertContainer>
          <BannerAlert appearance="critical">
            {authenticationErrorTranslated && <Text id={authenticationErrorTranslated} />}
            {authenticationErrorMessage && authenticationErrorMessage}
          </BannerAlert>
        </StyledBannerAlertContainer>
      )}
      {linkSent && (
        <StyledBannerAlertContainer>
          <BannerAlert appearance="success">
            <HtmlText
              id="login.linkSentHelp"
              interpolations={{
                'LINK-BEGIN':
                  '<a target="_blank" href="https://support.trustpilot.com/hc/articles/24938857772306-New-login-method-to-Trustpilot-Business-FAQ">',
                'LINK-END': '</a>',
              }}
            />
          </BannerAlert>
        </StyledBannerAlertContainer>
      )}
      <StyledInputFieldWrapper marginBottom={16}>
        <Input
          id="email"
          name="email"
          value={email}
          placeholder={translations['common.workEmail']}
          onChange={(evt) => handleEmailChange(evt.target.value)}
          onEnterPress={handleAuthenticate}
          disabled={inProgress}
        />
      </StyledInputFieldWrapper>
      {authenticationStep === AuthenticationStep.LOGIN && (
        <>
          <StyledInputFieldWrapper marginBottom={8}>
            <Input
              id="password"
              name="password"
              value={password}
              data-lpignore="false"
              type={passwordVisible ? 'text' : 'password'}
              placeholder={translations['common.password']}
              onChange={(evt) => setPassword(evt.target.value)}
              onEnterPress={handleAuthenticate}
              disabled={inProgress}
            />
          </StyledInputFieldWrapper>

          <PasswordActionsContainer>
            <span id="forgot-password">
              <Link href={Routes.FORGOT_PASSWORD + window.location.search} appearance="subtle">
                <Text id="common.forgotYourPassword" />
              </Link>
            </span>
            <Link onClick={() => setPasswordVisible(!passwordVisible)} appearance="primary">
              <Text id={!passwordVisible ? 'common.showPassword' : 'common.hidePassword'} />
            </Link>
          </PasswordActionsContainer>
        </>
      )}
      {!authenticateInProgress && (
        <StyledLoginButton
          disabled={loginDisabled}
          onClick={handleAuthenticate}
          appearance="primary"
          size="large"
          stretch
          data-testid="login"
        >
          <Text id="login.logIn" />
        </StyledLoginButton>
      )}
      {authenticateInProgress && <Loading />}
      {(googleAuthenticationEnabled || ssoAuthenticationEnabled) && (
        <React.Fragment>
          <StyledSSOLoginButtonsContainer>
            <StyledDividerContainer>
              <StyledDividerLine />
              <StyledDividerText>
                <Typography appearance="subtle">
                  <Text id="login.or" />
                </Typography>
              </StyledDividerText>
              <StyledDividerLine />
            </StyledDividerContainer>
            {!ssoAuthenticationEnabled && googleAuthenticationEnabled && (
              <StyledGoogleLoginHeader>
                <Text id="login.googleApps" />
              </StyledGoogleLoginHeader>
            )}
            {googleAuthenticationEnabled && (
              <>
                {pendingRedirect || googleAuthenticateInProgress ? (
                  <Loading />
                ) : (
                  <StyledSSOLoginButton
                    onClick={handleAuthenticateWithGoogle}
                    size="large"
                    stretch
                    data-testid="login-with-google"
                    iconOverride={<GoogleIcon />}
                  >
                    <Text id="login.loginWithGoogle" />
                  </StyledSSOLoginButton>
                )}
              </>
            )}
            {ssoAuthenticationEnabled && (
              <StyledSSOLoginButton
                href={Routes.SSO_LOGIN + window.location.search}
                size="large"
                stretch
                data-testid="login-with-sso"
                iconOverride={<StyledKeyIcon content={KeyIcon} />}
              >
                <Text id="login.loginWithSso" />
              </StyledSSOLoginButton>
            )}
          </StyledSSOLoginButtonsContainer>
        </React.Fragment>
      )}
      <FooterContainer>
        <Typography variant="body-m">
          <Text
            id="login.dontHaveAccount"
            interpolations={{
              LINK: (content) => <Link onClick={onClickSignup}>{content}</Link>,
            }}
            suffix={{ begin: '-BEGIN', end: '-END' }}
          />
        </Typography>
      </FooterContainer>
    </Card>
  );
};

const StyledKeyIcon = styled(Icon)({
  fill: 'black',
});

const StyledLoginButton = styled(Button)({
  fontWeight: 'normal',
});

const StyledSSOLoginButton = styled(Button)({
  fontWeight: 'normal',
});

const StyledSSOLoginButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const StyledBannerAlertContainer = styled.div({
  marginBottom: 24,
});

const StyledGoogleLoginHeader = styled.div({
  color: '#A3A3A3',
  fontSize: 14,
});

const StyledDividerText = styled.div({
  padding: '0 24px',
});

const StyledDividerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
  color: '#A3A3A3',
  fontSize: 14,
});

const StyledDividerLine = styled.div({
  width: '100%',
  backgroundColor: '#A3A3A3',
  content: '""',
  display: 'inline-block',
  height: '1px',
  position: 'relative',
  verticalAlign: 'middle',
  opacity: 0.5,
});

const PasswordActionsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 24,
  fontSize: 14,
});

const FooterContainer = styled.div({
  marginTop: 20,
});

const CenteredContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
