import { createActions } from 'redux-actions';

export const {
  loginForm: {
    initialize,
    setInitialized,
    authenticateWithGoogle,
    authenticate,
    setEmail,
    setPassword,
    setAuthenticationErrorCode,
    setAuthenticationErrorMessage,
    setGoogleAuthenticateInProgress,
    setGoogleAuthenticationEnabled,
    setAuthenticateInProgress,
    onClickSignup,
    linkSent,
    emailEntered,
    setConnectionId,
  },
}: any = createActions({
  LOGIN_FORM: {
    INITIALIZE: null,
    SET_INITIALIZED: null,
    AUTHENTICATE_WITH_GOOGLE: null,
    AUTHENTICATE: null,
    SET_EMAIL: null,
    SET_PASSWORD: null,
    SET_AUTHENTICATION_ERROR_CODE: null,
    SET_AUTHENTICATION_ERROR_MESSAGE: null,
    SET_GOOGLE_AUTHENTICATE_IN_PROGRESS: null,
    SET_GOOGLE_AUTHENTICATION_ENABLED: null,
    SET_AUTHENTICATE_IN_PROGRESS: null,
    ON_CLICK_SIGNUP: null,
    LINK_SENT: null,
    EMAIL_ENTERED: (email) => ({ email }),
    SET_CONNECTION_ID: (connectionId) => ({ connectionId }),
  },
});
