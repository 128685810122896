import { handleOpener } from './handleOpener';

let broadcastChannel: BroadcastChannel;

export const openBroadcastChannel = () => {
  broadcastChannel = new BroadcastChannel('trustpilot-b2b-login');
  broadcastChannel.onmessage = ({ data: { type, payload } }) => {
    if (type === 'authentication' && window.opener) {
      handleOpener(payload);
    }
  };
};

export const closeBroadcastChannel = () => {
  if (broadcastChannel) {
    broadcastChannel.close();
  }
};

export const broadcastMessage = (message: unknown) => {
  if (!broadcastChannel) {
    broadcastChannel = new BroadcastChannel('trustpilot-b2b-login');
  }

  if (broadcastChannel) {
    broadcastChannel.postMessage(message);
  }
};
