import { handleActions } from 'redux-actions';
import * as Actions from '../actions/forgotPassword';

const initialState = {
  email: '',
  isValidEmail: false,
  showValidationErrors: false,
  inProgress: false,
  completed: false,
};

export const forgotPasswordReducer = handleActions<any>(
  {
    [Actions.setEmail]: (state, action) => {
      const email = action.payload;
      const isValidEmail = new RegExp('[^@]+@[^@]+\\.[^@]+', 'i').test(email);
      return { ...state, email, isValidEmail, showValidationErrors: false };
    },
    [Actions.resetPassword]: (state) => {
      return { ...state, showValidationErrors: true };
    },
    [Actions.setInProgress]: (state, action) => {
      return { ...state, inProgress: action.payload };
    },
    [Actions.success]: (state) => {
      return { ...state, inProgress: false, completed: true };
    },
  },
  initialState,
);
