import * as React from 'react';
import { PropsWithChildren } from 'react';
import { arrow_16 as ArrowIcon } from '@trustpilot/icons';
import styled from '@emotion/styled';
import { Text } from 'ilenia';
import { Icon, Link } from "@trustpilot/businessapp-patternlibrary";

type ContainerProps = {
  marginTop?: string;
};

type Props = {
  containerProps?: ContainerProps;
  onClick: () => void;
};

const BackToLoginLink: React.FC<PropsWithChildren<Props>> = ({ containerProps = {}, onClick }) => {
  return (
    <StyledContainer id="back-to-login" {...containerProps}>
      <Link appearance="subtle" onClick={onClick}>
        <StyledArrowIcon content={ArrowIcon} />
        <Text id="common.backToLogin" />
      </Link>
    </StyledContainer>
  );
};

const StyledContainer = styled.div((props: ContainerProps) => ({
  marginTop: props.marginTop,
}));

const StyledArrowIcon = styled(Icon)({
  transform: 'rotate(180deg)',
  marginRight: 8,
  marginBottom: '-2px'
});

export default BackToLoginLink;
