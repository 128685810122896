import { handleActions } from 'redux-actions';
import * as actions from '../actions/resetLostDevice';

const initialState = {
  success: false,
  inProgress: false,
  initialized: false,
  resetLostDeviceInformation: {
    email: '',
  },
};

export const resetLostDeviceReducer = handleActions<any>(
  {
    [actions.setInProgress]: (state, action) => {
      return { ...state, inProgress: action.payload };
    },
    [actions.setSuccess]: (state, action) => {
      return { ...state, success: action.payload };
    },
    [actions.setInitialized]: (state, action) => {
      return { ...state, initialized: action.payload };
    },
    [actions.setResetLostDeviceInformation]: (state, action) => {
      return { ...state, resetLostDeviceInformation: action.payload };
    },
  },
  initialState,
);
