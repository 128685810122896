import { createActions } from 'redux-actions';

export enum Providers {
  GOOGLE = 'google',
  PASSWORD = 'password',
  SSO = 'sso',
}

interface TrackLoginAttempt {
  email?: string;
  responseType?: string;
  provider: Providers;
  loginSuccess: boolean;
  redirectUrl?: string;
  providerId?: string;
}

export const {
  tracking: { init, initialized, identify, track, trackAndNavigate, trackLoginAttempt },
}: any = createActions({
  TRACKING: {
    INIT: null,
    INITIALIZED: null,
    IDENTIFY: (email: string): string => email,
    TRACK: (eventName: string, eventData?: Record<string, unknown>, email?: string) => ({
      eventName,
      eventData,
      email,
    }),
    TRACK_AND_NAVIGATE: (
      eventName: string,
      eventData?: Record<string, unknown>,
      url?: string,
      email?: string,
      target?: string,
    ) => ({
      eventName,
      eventData,
      url,
      email,
      target,
    }),
    TRACK_LOGIN_ATTEMPT: (props: TrackLoginAttempt) => {
      const { email, responseType, provider, loginSuccess, redirectUrl, providerId } = props || {};

      return {
        email,
        responseType,
        provider,
        loginSuccess,
        redirectUrl,
        providerId,
      };
    },
  },
});
