import axios from 'axios';
import Config from '../../../app/configuration';
import { PasswordlessResponse } from 'app/models/passwordlessResponse';

export async function isPasswordlessUser(email: string): Promise<PasswordlessResponse> {
  const response = await axios.get(`${Config.ApiUrl}/passwordless/${email}`);

  return response.data;
}

export async function sendLoginLink(email: string, url: string): Promise<PasswordlessResponse> {
  const response = await axios.post(`${Config.ApiUrl}/link`, { email, url });

  return response.data;
}
