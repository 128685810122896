import * as React from 'react';
import { useEffect } from 'react';
import { NarrowPage } from '@trustpilot/businessapp-patternlibrary';
import { ForgotPasswordProps } from '../../../components/pages/forgot-password/forgotPasswordContainer';
import HeaderComponent from '../../../components/header/headerComponent';
import ForgotPasswordContainer from '../../../components/forgot-password/forgotPasswordContainer';
import { useTranslations } from 'ilenia';
import { StyledCardContainer } from '../../../components/commonElements';

export const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const [translations] = useTranslations();

  useEffect(() => {
    document.title = translations['login.title'];
  }, []);

  return (
    <React.Fragment>
      <HeaderComponent />
      <NarrowPage>
        <StyledCardContainer id="forgot-pass-styled-cont">
          <ForgotPasswordContainer />
        </StyledCardContainer>
      </NarrowPage>
    </React.Fragment>
  );
};
