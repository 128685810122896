import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as loginActions from '../../actions/login';
import * as loginFormActions from '../../actions/loginForm';
import * as trackingActions from '../../actions/tracking';
import * as secondFactorVerificationActions from '../../actions/secondFactorVerification';
import * as lostDeviceActions from '../../actions/lostDevice';
import firebase from '../../firebase';
import { logError } from '../../sagas/error';
import * as api from './api';
import { AuthenticationError } from '../../models/authenticationError';
import * as commonApi from '../commonApi';
import * as qs from 'qs';
import { AuthenticationResponse } from '../../models/authenticationResponse';
import { locationService, tokenService } from '@trustpilot/businessapp-authentication';
import { AuthenticationStep } from '../../models/authenticationStep';
import { handleOpener } from '../../utils/handleOpener';

function getResponseType(): string {
  const responseType = window.location.search.match(/response_type=([^&|?]+)/);

  if (responseType && responseType.length > 1) {
    return responseType[1];
  }

  return '';
}

function* handleCredentials(getCredentials: any, query?: string) {
  let userCredential = null;
  try {
    userCredential = yield getCredentials;
  } catch (error) {
    if (error.code === 'auth/multi-factor-auth-required') {
      const resolver = firebase.getMultiFactorResolver(error);
      const {
        customData: {
          _serverResponse: { email, mfaInfo },
        },
      } = error;
      yield put(secondFactorVerificationActions.initialize(resolver, email, query, mfaInfo[0].phoneInfo));
      return;
    } else if (error.code === 'auth/user-disabled') {
      yield put(loginActions.setPendingRedirect(false));
      yield put(loginFormActions.setAuthenticationErrorCode(AuthenticationError.INACTIVE_USER));
      return;
    } else if (error.code === 'auth/user-not-found') {
      yield put(loginActions.setPendingRedirect(false));
      yield put(loginFormActions.setAuthenticationErrorCode(AuthenticationError.GOOGLE_USER_NOT_FOUND));
      return;
    } else if (error.code === 'auth/invalid-custom-token') {
      yield put(loginActions.setPendingRedirect(false));
      yield put(loginFormActions.setAuthenticationErrorCode(AuthenticationError.GOOGLE_CANNOT_AUTHORIZE));
    } else {
      throw error;
    }
  }

  const firebaseUser = userCredential?.user;
  const responseType = getResponseType();

  if (firebaseUser) {
    yield put(loginActions.setPendingRedirect(true));
    yield put(loginActions.setInitialized(true));
    const idTokenResult = yield call(firebase.getCurrentUserIdTokenResult);
    const idToken = idTokenResult.token;

    let authenticationResponse: AuthenticationResponse = null;

    if (idTokenResult?.claims?.is_sso_user) {
      const { sso_user_email, sso_provider_id } = idTokenResult.claims;
      authenticationResponse = yield call(commonApi.ssoAuthentication, idToken, sso_user_email, sso_provider_id);
    } else if (idTokenResult?.signInProvider === 'custom') {
      authenticationResponse = yield call(
        api.businessLogin,
        window.location.search.slice(1),
        firebaseUser.email,
        idToken,
      );
    } else {
      const googleCredentials = yield call(firebase.getGoogleCredentials, userCredential);

      authenticationResponse = yield call(commonApi.googleAuthentication, idToken, googleCredentials.idToken);
    }

    if (authenticationResponse.isValid) {
      if (handleOpener(authenticationResponse)) {
        return;
      }

      yield put(
        trackingActions.trackLoginAttempt({
          email: firebaseUser.email,
          responseType,
          provider: trackingActions.Providers.GOOGLE,
          loginSuccess: true,
          redirectUrl: authenticationResponse.redirectUrl,
        }),
      );
    } else {
      yield put(
        trackingActions.trackLoginAttempt({
          email: firebaseUser.email,
          responseType,
          provider: trackingActions.Providers.GOOGLE,
          loginSuccess: false,
        }),
      );

      yield put(loginActions.setPendingRedirect(false));
    }

    yield put(loginFormActions.setAuthenticationErrorCode(authenticationResponse.authenticationError));
  } else {
    yield put(loginActions.setPendingRedirect(false));
    yield put(loginActions.setInitialized(true));
  }
}

function* initialize() {
  try {
    const query = window.location.search.substr(1);
    const parsedQuery = qs.parse(query);
    const localeParameter = parsedQuery.locale as string;
    const customToken = parsedQuery.customToken as string;
    yield put(loginActions.setCustomToken(customToken));

    const locale = yield call(commonApi.getLocale, localeParameter);
    yield put(loginActions.setLocale(locale));

    const pendingRedirect = window.sessionStorage.getItem('pendingRedirect') === 'true';
    window.sessionStorage.removeItem('pendingRedirect');

    yield put(loginActions.setPendingRedirect(pendingRedirect));
    yield put(loginActions.setInitialized(pendingRedirect));

    yield handleCredentials(
      customToken ? call(firebase.signInWithCustomToken, customToken) : call(firebase.getRedirectResult),
      query,
    );
  } catch (error) {
    yield put(loginActions.setPendingRedirect(false));
    yield put(loginFormActions.setAuthenticationErrorCode(AuthenticationError.GOOGLE_UNEXPECTED_ERROR));
    yield call(logError, error);
  }
}

function* signInWithGoogle() {
  try {
    yield handleCredentials(call(firebase.signInWithGoogle));
  } catch (error) {
    yield put(loginActions.setPendingRedirect(false));
    yield put(loginFormActions.setAuthenticationErrorCode(AuthenticationError.GOOGLE_UNEXPECTED_ERROR));
    yield call(logError, error);
  }
}

function* verifyCurrentUserAndRedirect(action) {
  const { email, queryString, isSecondFactorLogin } = action.payload;

  const idTokenResult = yield call(firebase.getCurrentUserIdTokenResult);
  const token = idTokenResult.token;

  const authentication: AuthenticationResponse = yield call(api.businessLogin, queryString, email, token);

  if (!authentication.isValid) {
    throw new Error(authentication.message);
  } else {
    if (handleOpener(authentication)) {
      return;
    }

    yield redirectToUri(
      authentication.email,
      authentication.businessUnitIds[0],
      authentication.redirectUrl,
      isSecondFactorLogin,
    );
  }
}

function* redirectToUri(email: string, businessUnitId: string, redirectUri: string, isSecondFactorLogin: boolean) {
  if (locationService.isIntegrationConnectAppsUsed()) {
    tokenService.setAccessTokenFromImplicitFlow();
  }

  yield put(
    trackingActions.trackAndNavigate(
      'BusinessUserLoggedIn',
      { businessUnitId, isSecondFactorLogin },
      redirectUri,
      email,
    ),
  );
}

function* onSecondFactorVerificationInitialized(action) {
  try {
    if (action.payload === true) {
      yield put(loginActions.setAuthenticationStep(AuthenticationStep.SECOND_FACTOR_VERIFICATION));
      yield put(loginActions.setPendingRedirect(false));
    }
  } catch (e) {
    yield logError(e);
  }
}

function* onSecondFactorVerificationInitializationFailed() {
  try {
    yield put(loginFormActions.setAuthenticateInProgress(false));
  } catch (e) {
    yield logError(e);
  }
}

function* onLostDeviceInitialized() {
  try {
    yield put(loginActions.setAuthenticationStep(AuthenticationStep.LOST_DEVICE));
  } catch (e) {
    yield logError(e);
  }
}

export default function* () {
  yield all([
    // note: not sure about this entire saga, but 'initialize' seems to be WIP (not called anywhere in app, commit msg says 'WIP')
    takeLatest(matchesType(loginActions.initialize), initialize),
    takeLatest(matchesType(loginActions.verifyCurrentUserAndRedirect), verifyCurrentUserAndRedirect),
    takeEvery(matchesType(secondFactorVerificationActions.setInitialized), onSecondFactorVerificationInitialized),
    takeEvery(
      matchesType(secondFactorVerificationActions.initializationFailed),
      onSecondFactorVerificationInitializationFailed,
    ),
    takeEvery(matchesType(lostDeviceActions.initialize), onLostDeviceInitialized),
    takeLatest(matchesType(loginActions.signInWithGoogle), signInWithGoogle),
  ]);
}
