import axios from 'axios';
import Config from '../../../app/configuration';

export async function getChangePasswordData(secret) {
  try {
    const response = await axios.get(`${Config.ApiUrl}/change-password/${secret}?v=2`);

    return response.data;
  } catch (e) {
    if (e.response.status === 404) {
      return null;
    }

    throw e;
  }
}

export async function changePassword(secret, password, secondFactorToken) {
  await axios.post(`${Config.ApiUrl}/change-password/${secret}?v=2`, {
    password,
    secondFactorToken,
  });
}
