import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../actions/ssoLoginActions';
import { SsoLoginComponent } from './ssoLoginComponent';

const mapDispatchToProps = {
  ...actions,
};

const mapStateToProps = (state) => ({
  ...state.ssoLogin,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SsoLoginComponentProps = ConnectedProps<typeof connector>;

const SsoLoginComponentContainer = connector(SsoLoginComponent);

export default SsoLoginComponentContainer;
