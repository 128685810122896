import { createActions } from 'redux-actions';

export const {
  lostDevice: {
    initialize,
    setInitialized,
    setInProgress,
    setError,
    submitLostDevice,
    setSuccess,
    reset,
    backToSecondFactor,
  },
}: any = createActions({
  LOST_DEVICE: {
    INITIALIZE: (email) => ({ email }),
    SET_INITIALIZED: null,
    SET_IN_PROGRESS: null,
    SET_ERROR: null,
    SUBMIT_LOST_DEVICE: (email, phoneNumber) => ({ email, phoneNumber }),
    SET_SUCCESS: null,
    RESET: null,
    BACK_TO_SECOND_FACTOR: null,
  },
});
