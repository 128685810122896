import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@trustpilot/businessapp-patternlibrary';
import { Text } from 'ilenia';

const PasswordRules = ({ passwordValidation }) => {
  return (
    <StyledRootContainer>
      <StyledPasswordRule>
        <StyledRuleIcon valid={passwordValidation.validation8Characters} />
        <Text id="passwordRules.validation8Characters" />
      </StyledPasswordRule>
      <StyledPasswordRule>
        <StyledRuleIcon valid={passwordValidation.validationLowercaseCharacter} />
        <Text id="passwordRules.validationLowercaseCharacter" />
      </StyledPasswordRule>
      <StyledPasswordRule>
        <StyledRuleIcon valid={passwordValidation.validationUppercaseCharacter} />
        <Text id="passwordRules.validationUppercaseCharacter" />
      </StyledPasswordRule>
      <StyledPasswordRule>
        <StyledRuleIcon valid={passwordValidation.validationOneNumber} />
        <Text id="passwordRules.validationOneNumber" />
      </StyledPasswordRule>
    </StyledRootContainer>
  );
};

const StyledRootContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 22,
  marginTop: 8,
  '> div:not(:last-child)': {
    marginBottom: 16,
  },
});

const StyledPasswordRule = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const StyledRuleIcon = styled.div(
  {
    height: 17,
    width: 17,
    border: `1px solid ${colors.gray['60']}`,
    borderRadius: 10,
    marginRight: 5,
  },
  (props: { valid: boolean }) => ({
    ...(props.valid
      ? {
          border: `1px solid ${colors.green['10']}`,
          background: `url(https://images-static.trustpilot.com/b2b/settings/checkmark-enabled.svg) ${colors.green['10']} no-repeat 45% 50%`,
        }
      : {
          border: `1px solid ${colors.gray['60']}`,
          background: `url(https://images-static.trustpilot.com/b2b/settings/checkmark-enabled.svg) ${colors.gray['60']} no-repeat 45% 50%`,
        }),
  }),
);

export default PasswordRules;
