import { BannerAlert, Button } from '@trustpilot/businessapp-patternlibrary';
import { Text } from 'ilenia';
import * as React from 'react';
import { Spacer32, StyledButtonLink } from '../../components/commonElements';

const ChangePasswordSuccess = ({ businessWebApp }) => {
  return (
    <React.Fragment>
      <BannerAlert className="password-changed" appearance="info">
        <Text id="changePassword.passwordChanged" />
      </BannerAlert>
      <Spacer32 />
      <StyledButtonLink href={businessWebApp}>
        <Button data-testid="back-to-login" stretch size="large" appearance="primary">
          <Text id="common.goToLogin" />
        </Button>
      </StyledButtonLink>
    </React.Fragment>
  );
};

export default ChangePasswordSuccess;
