import SecondFactorCodeForm from '../../components/second-factor-code-form/secondFactorCodeForm';
import React, { useEffect, useState } from 'react';
import { ChangePasswordSecondFactorFormProps } from './changePasswordSecondFactorFormContainer';

const ChangePasswordSecondFactorForm: React.FC<ChangePasswordSecondFactorFormProps> = ({
  initialized,
  inProgress,
  phoneNumber,
  initialize,
  submitVerificationCode,
  resendCode,
  error,
  resendCodeCountdown,
  reset,
  headerText,
  mainButtonActionText,
  secret,
}) => {
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (ref) {
      initialize(secret);
    }
  }, [ref]);

  useEffect(() => () => reset(), []);

  return (
    <>
      <SecondFactorCodeForm
        submitVerificationCode={submitVerificationCode}
        inProgress={!initialized || inProgress}
        phoneNumber={phoneNumber}
        resendCode={() => resendCode(secret)}
        resendCodeCountdown={resendCodeCountdown}
        error={error}
        lostDeviceEnabled={false}
        headerText={headerText}
        mainButtonActionText={mainButtonActionText}
      />
      <div id="recaptcha-container" ref={(ref) => setRef(ref)} />
    </>
  );
};

export default ChangePasswordSecondFactorForm;
