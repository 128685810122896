import styled from '@emotion/styled';
import * as React from 'react';
import { Button, Card, Typography } from '@trustpilot/businessapp-patternlibrary';

type StyledInputProps = {
  notEmpty?: boolean;
  dotsEnabled?: boolean;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

type StyledInputFloatingLabelProps = {
  isUp: boolean;
  topLabelUp: number;
  top: number;
};

type StyledInputFieldWrapperProps = {
  marginBottom: number;
};

type StyledShowPasswordButtonProps = {
  passwordEntered: boolean;
};

type StyledDescriptionProps = {
  marginBottom: number;
};

type StyledCardContainerProps = {
  maxWidth?: number;
};

export const StyledHeader = ({ className = '', children }) => (
  <StyledHeaderContainer>
    <Typography variant="heading-m" className={className}>
      {children}
    </Typography>
  </StyledHeaderContainer>
);

export const StyledHeaderContainer = styled.div({
  marginBottom: 32,
  marginTop: 3.2,
});

export const MainActionButton = ({ disabled, onClick = null, ...rest }) => {
  const handleOnClick = (...args) => {
    if (!disabled && onClick !== null) {
      onClick(...args);
    }
  };

  return <Button appearance="primary" onClick={handleOnClick} aria-label="main-action" disabled={disabled} {...rest} />;
};

export const StyledInput = styled.input<StyledInputProps>(
  {
    boxShadow: 'inset 0 1px 2px rgba(182,196,210,.3)',
    color: '#000',
    position: 'relative',
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #d9d9d9',
    borderRadius: 4,
    boxSizing: 'border-box',
    height: 52,
    outline: 'none',
    '&:hover': {
      borderColor: '#0c59f2',
      boxShadow: 'none',
    },
    '&:focus': {
      borderColor: '#0c59f2',
      boxShadow: 'none',
    },
  },
  (props: StyledInputProps) => ({
    padding: props.notEmpty ? '16px 16px 4px !important' : '4px 11px !important',
    fontSize: props.dotsEnabled ? 21 : 14,
    ...(props.error ? { border: '1px solid rgb(252, 75, 92)' } : {}),
    ...(props.disabled || props.readOnly ? { background: 'var(--star-zero)', cursor: 'no-drop' } : {}),
  }),
);

export const StyledInputFloatingLabel = styled.label<StyledInputFloatingLabelProps>(
  {
    position: 'absolute',
    left: 16,
    transition: 'all 200ms',
    zIndex: 2,
    height: 24,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  (props: StyledInputFloatingLabelProps) => ({
    color: !props.isUp ? '#4d4d4d' : '#9c9ca6',
    fontSize: !props.isUp ? '16px !important' : '11px !important',
    opacity: !props.isUp ? 0.5 : 1,
    top: !props.isUp ? props.top : props.topLabelUp,
  }),
);

export const StyledInputErrorList = ({ errors }) => {
  return (
    <StyledUnorderedErrorList>
      {errors.map((error) => (
        <li key={error}>{error}</li>
      ))}
    </StyledUnorderedErrorList>
  );
};

const StyledUnorderedErrorList = styled.ul({
  color: 'rgb(252, 76, 93)',
  fontSize: 12,
  fontWeight: 'bold',
  paddingLeft: 16,
  marginTop: 8,
});

export const StyledInputFieldWrapper = styled.div<StyledInputFieldWrapperProps>(
  {
    position: 'relative',
    marginBottom: 16,
  },
  (props: StyledInputFieldWrapperProps) => ({
    marginBottom: props.marginBottom,
  }),
);

export const StyledCard = styled(Card)({
  padding: '40px',
});

export const StyledCardContainer = styled.div<StyledCardContainerProps>(
  {
    width: '100%',
    maxWidth: 560,
    margin: '48px auto 24px',
    position: 'relative',
  },
  (props: StyledCardContainerProps) => ({
    maxWidth: props.maxWidth ? props.maxWidth : null,
  }),
);

export const StyledDescription = styled.p<StyledDescriptionProps>(
  {
    fontSize: '16px',
    lineHeight: '1.6',
    marginTop: 0,
    fontWeight: 'normal',
    color: '#222222',
  },
  (props: StyledDescriptionProps) => ({
    marginBottom: props.marginBottom ? props.marginBottom : null,
  }),
);

export const StyledShowPasswordButton = styled.div<StyledShowPasswordButtonProps>(
  {
    cursor: 'pointer',
  },
  (props: StyledShowPasswordButtonProps) => ({
    color: props.passwordEntered ? '#0C59F2' : '#A3A3A3 !important',
    '&:hover': {
      color: props.passwordEntered && '#0E4BC4',
    },
  }),
);

const StyledSignupLink = styled.a({
  cursor: 'pointer',
  color: '#0C59F2',
});

export const getDontHaveAccountElements = (message, onClick) => {
  const linkBegin = '[LINK-BEGIN]';
  const linkEnd = '[LINK-END]';
  const linkBeginIdx = message.indexOf(linkBegin);
  const linkEndIdx = message.indexOf(linkEnd);
  const linkText = message.substr(linkBeginIdx + linkBegin.length, linkEndIdx - linkBeginIdx - linkBegin.length);

  return [
    message.substr(0, linkBeginIdx),
    <StyledSignupLink key="link" onClick={onClick}>
      {linkText}
    </StyledSignupLink>,
    message.substr(linkEndIdx + linkEnd.length),
  ];
};

export const Spacer32 = styled.div({ height: '32px' });

export const StyledButtonLink = styled.a({
  textDecoration: 'none',
});
