import * as React from 'react';
import { useEffect } from 'react';
import { NarrowPage } from '@trustpilot/businessapp-patternlibrary';
import HeaderComponent from '../../../components/header/headerComponent';
import { LinkLoginProps } from './linkLoginContainer';
import { StyledCardContainer } from '../../../components/commonElements';
import LinkLoginFormContainer from '../../../components/link-login-form/linkLoginFormContainer';
import { AuthenticationStep } from '../../../models/authenticationStep';
import SecondFactorVerificationContainer from '../../../components/second-factor-verification/secondFactorVerificationContainer';
import firebase from '../../../firebase';

export const LinkLogin: React.FC<LinkLoginProps> = ({ authenticateLink, authenticationStep }) => {
  useEffect(() => {
    authenticateLink(window.location.href);
  }, [authenticateLink]);

  const [ref, setRef] = React.useState(null);

  useEffect(() => {
    if (ref) {
      firebase.initRecaptchaVerifier();
    }
  }, [ref]);

  const Component =
    authenticationStep === AuthenticationStep.SECOND_FACTOR_VERIFICATION
      ? SecondFactorVerificationContainer
      : LinkLoginFormContainer;

  return (
    <React.Fragment>
      <HeaderComponent />
      <NarrowPage>
        <StyledCardContainer id="login-styled-page-container">
          <Component />
        </StyledCardContainer>
      </NarrowPage>
      <div id="recaptcha-container" ref={(ref) => setRef(ref)} />
    </React.Fragment>
  );
};
