import * as React from 'react';
import { useEffect } from 'react';
import { NarrowPage } from '@trustpilot/businessapp-patternlibrary';
import HeaderComponent from '../../../components/header/headerComponent';
import { ResetLostDeviceProps } from '../../../components/pages/reset-lost-device/resetLostDeviceContainer';
import ResetLostDeviceContainer from '../../../components/reset-lost-device/resetLostDeviceContainer';
import { StyledCardContainer } from '../../../components/commonElements';
import { useTranslations } from 'ilenia';

export const ResetLostDevice: React.FC<ResetLostDeviceProps> = ({ token }) => {
  const [translations] = useTranslations();

  useEffect(() => {
    document.title = translations['resetLostDevice.title'];
  }, []);

  if (!token) {
    window.location.href = '/';
    return null;
  }

  return (
    <React.Fragment>
      <HeaderComponent />
      <NarrowPage>
        <StyledCardContainer>
          <ResetLostDeviceContainer token={token} />
        </StyledCardContainer>
      </NarrowPage>
    </React.Fragment>
  );
};
