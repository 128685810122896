import { all, call, put, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/app';
import { GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import { logError } from '../../sagas/error';
import qs from 'qs';
import * as trackingActions from '../../actions/tracking';
import { CookieConsent } from '@trustpilot/businessapp-browserstorage';

function* initialize() {
  try {
    const queryParameters = qs.parse(location.search.substr(1));
    const locale = (queryParameters.locale as string) || 'en-US';

    // [TODO] temporary cleanup for the boundaries merging transition period. This will be removed.
    document.cookie =
      'OptanonConsent=; path=/ ;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.authenticate.trustpilot.com';
    document.cookie =
      'OptanonAlertBoxClosed=; path=/ ;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.authenticate.trustpilot.com';

    yield call(CookieConsent.init, {
      locale,
    });
    yield put(trackingActions.init());

    yield put(actions.setLocale(locale));
    yield put(actions.setInitialized(true));
  } catch (error) {
    GlobalAlertService.critical('Error occurred while initializing application.', 3000);
    yield call(logError, error);
  }
}

export default function*() {
  yield all([takeLatest(matchesType(actions.initialize), initialize)]);
}
