import { all, call, put, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/resetLostDevice';
import { logError } from '../../sagas/error';
import * as api from './api';
import { GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import * as trackingActions from '../../actions/tracking';

function* initialize(action) {
  try {
    const token = action.payload;

    const resetLostDeviceInformation = yield call(api.getResetLostDeviceInformation, token);

    yield put(actions.setResetLostDeviceInformation(resetLostDeviceInformation));
    yield put(actions.setInitialized(true));
  } catch (e) {
    yield put(actions.setInitialized(false));
    GlobalAlertService.critical('Error occurred while initializing reset 2-step verification', 3000);

    yield call(logError, e);
  }
}

function* resetLostDevice(action) {
  const { token, password, email } = action.payload;

  try {
    yield put(actions.setInProgress(true));

    yield call(api.resetLostDevice, token, password);

    yield call(trackResetLostDevice, email, true);
    yield put(actions.setSuccess(true));
  } catch (e) {
    yield call(trackResetLostDevice, email, false);
    yield put(actions.setInProgress(false));
    GlobalAlertService.critical('Error occurred while resetting 2-step verification', 3000);

    yield call(logError, e);
  }
}

function* trackResetLostDevice(email, success) {
  try {
    yield put(
      trackingActions.track(
        'Button Clicked',
        {
          action: 'Submit reset lost device',
          context: 'businessauthentication-businessapp',
          flow: 'reset-lost-device',
          success,
        },
        email,
      ),
    );
  } catch (error) {
    yield call(logError, error);
  }
}

export default function*() {
  yield all([
    takeLatest(matchesType(actions.initialize), initialize),
    takeLatest(matchesType(actions.resetLostDevice), resetLostDevice),
  ]);
}
