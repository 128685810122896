import * as actions from '../../../actions/activateAccountActions';
import { connect, ConnectedProps } from 'react-redux';
import { ForgotPassword } from './forgotPassword';

const mapDispatchToProps = {
  verify: actions.verify,
  initialize: actions.initialize,
};

const mapStateToProps = (state) => ({
  activationState: state.activateAccount.activationState,
  initialized: state.activateAccount.initialized,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ForgotPasswordProps = ConnectedProps<typeof connector>;

const ForgotPasswordContainer = connector(ForgotPassword);

export default ForgotPasswordContainer;
