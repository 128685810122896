import * as React from 'react';
import { SsoLoginComponentProps } from './ssoLoginComponentContainer';
import styled from '@emotion/styled';
import { Text, useTranslations } from 'ilenia';
import { StyledCard, StyledInputFieldWrapper } from '../commonElements';
import { BannerAlert, Button, Input } from '@trustpilot/businessapp-patternlibrary';
import { AuthenticationErrorMessage } from '../../models/authenticationError';
import { LargeKeyIcon } from '../large-key-icon/largeKeyIcon';
import BackToLoginLink from '../back-to-login-link/backToLoginLink';

const SSORequiredMessage = () => (
  <StyledSSORequiredContainer>
    <StyledExclamationSVG>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM6.75 11.75C6.75 11.0626 7.30721 10.5 8 10.5C8.68824 10.5 9.25 11.0618 9.25 11.75C9.25 12.4428 8.6874 13 8 13C7.30805 13 6.75 12.4419 6.75 11.75ZM7.75 3C7.19772 3 6.75 3.44772 6.75 4V8.25C6.75 8.80229 7.19772 9.25 7.75 9.25H8.25C8.80229 9.25 9.25 8.80228 9.25 8.25V4C9.25 3.44772 8.80229 3 8.25 3H7.75Z"
        fill="#FFAF05"
      />
    </StyledExclamationSVG>
    <StyledSSORequiredText>
      <Text id="login.ssoRequiredMessage" />
    </StyledSSORequiredText>
  </StyledSSORequiredContainer>
);

export const SsoLoginComponent: React.FC<SsoLoginComponentProps> = ({
  domain,
  setDomain,
  inProgress,
  login,
  errorCode,
  isSSOEnforcedRedirection,
}) => {
  const [translations] = useTranslations();
  const translatedError = AuthenticationErrorMessage[errorCode];

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && domain) {
      login();
    }
  };

  return (
    <StyledCard>
      {translatedError && (
        <StyledBannerAlertContainer>
          <BannerAlert appearance="critical">{translatedError && <Text id={translatedError} />}</BannerAlert>
        </StyledBannerAlertContainer>
      )}
      {isSSOEnforcedRedirection && <SSORequiredMessage />}
      <StyledSSOHeaderContainer>
        <StyledIconContainer>
          <LargeKeyIcon />
        </StyledIconContainer>
        <StyledSSOHeaderText>
          <Text id="login.loginWithSso" />
        </StyledSSOHeaderText>
        <StyledSSOHeaderDescriptionText>
          <Text id="login.ssoDescription" />
        </StyledSSOHeaderDescriptionText>
      </StyledSSOHeaderContainer>
      <StyledInputFieldWrapper marginBottom={16}>
        <Input
          id="domain"
          label={<Text id="login.ssoDomainInput.label" />}
          placeholder={translations['login.ssoDomainInput.placeholder']}
          value={domain}
          onChange={(evt) => setDomain(evt.target.value)}
          onEnterPress={(event) => handleKeyPress(event)}
        />
      </StyledInputFieldWrapper>
      <StyledLoginButton
        onClick={login}
        disabled={inProgress || !domain}
        loading={inProgress}
        appearance="primary"
        stretch
        size="large"
        data-testid="sso-login"
      >
        <Text id="login.logIn" />
      </StyledLoginButton>
      <BackToLoginLink
        containerProps={{ marginTop: '32px' }}
        onClick={() => (window.location.href = '/' + window.location.search)}
      />
    </StyledCard>
  );
};

const StyledIconContainer = styled.div({
  marginBottom: '40px',
});

const StyledLoginButton = styled(Button)({
  fontWeight: 'normal',
});

const StyledSSOHeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '60px',
});

const StyledSSOHeaderText = styled.div({
  fontWeight: 'bold',
  color: 'var(--text-default)',
  fontSize: '24px',
  lineHeight: '32px',
  marginBottom: '12px',
});

const StyledSSOHeaderDescriptionText = styled.div({
  color: 'var(--text-subtle)',
  fontSize: '16px',
});

const StyledBannerAlertContainer = styled.div({
  marginBottom: 24,
});

const StyledSSORequiredContainer = styled.div({
  width: '93%',
  padding: 16,
  background: '#FFEFCD',
  borderRadius: 2,
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 16,
  display: 'inline-flex',
  margin: '0 0 50px 0',
});

const StyledSSORequiredText = styled.div({
  flex: '1 1 0',
  color: '#1C1C1C',
  fontWeight: '400',
  letterSpacing: 0.3,
  wordWrap: 'break-word',
});

const StyledExclamationSVG = styled.svg({
  fill: 'none',
  height: 16,
  width: 16,
  viewBox: '0 0 16 16',
  xmlns: 'http://www.w3.org/2000/svg',
});
