import * as actions from '../../../actions/ssoLoginActions';
import { connect, ConnectedProps } from 'react-redux';
import { SsoLogin } from './ssoLogin';

const mapDispatchToProps = {
  initialize: actions.initialize,
  setDomain: actions.setDomain,
};

const mapStateToProps = (state) => ({
  initialized: state.ssoLogin.initialized,
  inProgress: state.ssoLogin.inProgress,
  success: state.ssoLogin.success,
  errorCode: state.ssoLogin.errorCode,
  isSSOEnforcedRedirection: state.ssoLogin.isSSOEnforcedRedirection,
  authenticationStep: state.login.authenticationStep,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SsoLoginProps = ConnectedProps<typeof connector>;

const SsoLoginContainer = connector(SsoLogin);

export default SsoLoginContainer;
