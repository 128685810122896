import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createRootReducer, RootState } from '../reducers';
import { History } from 'history';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { all } from 'redux-saga/effects';

export function configureStore(history: History, sagas: Saga[]): Store<RootState> {
  const rootSaga = createRootSaga(sagas);

  const sagaMiddleware = createSagaMiddleware();
  let middleware = applyMiddleware(sagaMiddleware);

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware) as any;
  }

  const store = createStore(createRootReducer() as any, middleware) as Store<RootState>;

  sagaMiddleware.run(rootSaga);

  return store;
}

const createRootSaga = (sagas: Saga[]) =>
  function*() {
    yield all([...sagas].map((saga) => saga()));
  };
