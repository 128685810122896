import * as React from 'react';
import { Text, useTranslations } from 'ilenia';
import { ForgotPasswordComponentProps } from '../../components/forgot-password/forgotPasswordContainer';
import styled from '@emotion/styled';
import { LinkStyles } from '../link/linkComponent';
import {
  StyledCard,
  StyledDescription,
  StyledHeader,
  StyledHeaderContainer,
  StyledInputFieldWrapper,
} from '../commonElements';
import { escapedHtml } from '../../utils/escapedHtml';
import BackToLoginLink from '../back-to-login-link/backToLoginLink';
import { Button, Input, Typography } from '@trustpilot/businessapp-patternlibrary';

export const ForgotPasswordComponent: React.FC<ForgotPasswordComponentProps> = ({
  email,
  isValidEmail,
  inProgress,
  completed,
  setEmail,
  resetPassword,
}) => {
  const [translations] = useTranslations();

  const onClickSendEmail = () => {
    if (isValidEmail) {
      resetPassword(email);
    }
  };

  const handleEnterPress = () => {
    if (email) {
      onClickSendEmail();
    }
  };

  const didntReceiveEmail = translations['forgotPassword.didntReceiveEmail']
    .replace(
      '[LINK-START]',
      '<a id="support-article" href="https://support.trustpilot.com/hc/articles/201810857-Having-problems-logging-in-to-Trustpilot-Business">',
    )
    .replace('[LINK-END]', '</a>');

  return (
    <StyledCard>
      {!completed ? (
        <React.Fragment>
          <StyledHeaderContainer className="forgot-your-password">
            <Typography variant="heading-m">
              <Text id="common.forgotYourPassword" />
            </Typography>
          </StyledHeaderContainer>
          <div style={{ marginBottom: '16px' }}>
            <Typography>{translations['forgotPassword.enterEmailWeWillSendInstructions']}</Typography>
          </div>
          <StyledInputFieldWrapper marginBottom={16}>
            <Input
              id="email"
              placeholder={translations['common.workEmail']}
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
              onEnterPress={handleEnterPress}
            />
          </StyledInputFieldWrapper>
          <StyledSubmitButtonContainer>
            <Button
              appearance="primary"
              onClick={onClickSendEmail}
              disabled={inProgress || !email}
              data-testid="send-email"
              stretch
            >
              <Text id="forgotPassword.sendEmail" />
            </Button>
          </StyledSubmitButtonContainer>
          <BackToLoginLink onClick={() => (window.location.href = '/' + window.location.search)} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <StyledHeader className="check-inbox">
            <Text id="forgotPassword.checkInbox" />
          </StyledHeader>
          <StyledDescription marginBottom={16} className="check-inbox-instructions">
            <span
              dangerouslySetInnerHTML={{
                __html: translations['forgotPassword.checkInboxInstructions'].replace(
                  '[EMAIL]',
                  `<b>${escapedHtml(email)}</b>`,
                ),
              }}
             />
          </StyledDescription>
          <FooterContainer>
            <StyledLinkContainer id="no-email" dangerouslySetInnerHTML={{ __html: didntReceiveEmail }} />
          </FooterContainer>
          <BackToLoginLink
            containerProps={{ marginTop: '32px' }}
            onClick={() => (window.location.href = '/' + window.location.search)}
          />
        </React.Fragment>
      )}
    </StyledCard>
  );
};

const StyledSubmitButtonContainer = styled.div({
  marginTop: '8px',
  marginBottom: '32px',
});

const FooterContainer = styled.div({
  fontSize: '0.8rem',
});

const StyledLinkContainer = styled.span({
  ' > a': LinkStyles,
});
