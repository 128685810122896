import { BannerAlert, Button, Input } from '@trustpilot/businessapp-patternlibrary';
import { Text, useTranslations } from 'ilenia';
import * as React from 'react';
import { StyledInputFieldWrapper } from '../../components/commonElements';
import styled from '@emotion/styled';
import PasswordRules from '../../components/password-rules/passwordRules';
import { validatePassword } from '../../utils/validatePassword';

const ChangePasswordForm = ({ setPassword, changePasswordData, password, errorCode, changePassword, inProgress }) => {
  const [translations] = useTranslations();
  const passwordValidation = validatePassword(password || '');

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
  };

  let errorMessage = null;

  if (errorCode === 1004) {
    errorMessage = 'changePassword.passwordInvalid';
  } else if (errorCode === 1005) {
    errorMessage = 'changePassword.passwordsDoNotMatch';
  }
  const disabled = inProgress || !passwordValidation.valid;

  return (
    <React.Fragment>
      {errorMessage && (
        <BannerAlert appearance="critical">
          <Text id={errorMessage} />
        </BannerAlert>
      )}
      <StyledHeader>
        {changePasswordData.changeEmailFlow ? (
          <Text id="changePassword.headerChangeEmail" />
        ) : (
          <Text id="changePassword.header" />
        )}
      </StyledHeader>
      <StyledInputFieldWrapper marginBottom={24}>
        <Input
          id="password"
          type="password"
          placeholder={translations['common.password']}
          value={password}
          onChange={handlePasswordChange}
          disabled={inProgress}
        />
      </StyledInputFieldWrapper>
      <PasswordRules passwordValidation={passwordValidation} />
      <StyledButtonContainer>
        <Button onClick={changePassword} data-testid="save-password" disabled={disabled}>
          <Text id="changePassword.savePassword" />
        </Button>
      </StyledButtonContainer>
    </React.Fragment>
  );
};

const StyledButtonContainer = styled.div({
  marginTop: '10px',
  marginBottom: '20px',
});

const StyledHeader = styled.div({
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: 27,
  marginBottom: 32,
  lineHeight: 1.4,
});

export default ChangePasswordForm;
