import * as React from 'react';
import styled from '@emotion/styled';
import { Text, useTranslations } from 'ilenia';
import { CookieConsent, getCookie } from '@trustpilot/businessapp-browserstorage';
import { GlobalAlertService, Link } from '@trustpilot/businessapp-patternlibrary';

const shouldShowDoNotSellMyInfo = () => {
  return getCookie('OptanonConsent').includes('geolocation=US');
};

const CookieLinks: React.FC = () => {
  const [translations] = useTranslations();

  const [showCookieLinks, setShowCookieLinks] = React.useState(CookieConsent.isOneTrustScriptInjected());
  const [showDoNotSellMyInfo, setShowDoNotSellMyInfo] = React.useState(shouldShowDoNotSellMyInfo());

  React.useEffect(() => {
    if (!showCookieLinks) {
      // sometimes the cookie banner loads too slowly, start listening for it
      const updateCookieLinkSettings = () => {
        setShowCookieLinks(true);
        setShowDoNotSellMyInfo(shouldShowDoNotSellMyInfo());
        document.removeEventListener('OptanonWrapper', updateCookieLinkSettings);
      };
      document.addEventListener('OptanonWrapper', updateCookieLinkSettings);
    }
  }, []);

  const onClick = React.useCallback((e) => {
    e.preventDefault();
    CookieConsent.showCookiesPreferences().catch(() => {
      GlobalAlertService.critical(translations['cookieConsent.error'], 3000);
    });
  }, []);

  return (
    <React.Fragment>
      {showCookieLinks && (
        <StyledCookieLinksContainer>
          <Link onClick={onClick}>
            <Text id="cookieConsent.preferences" />
          </Link>
          {showDoNotSellMyInfo && (
            <React.Fragment>
              <span>|</span>
              <Link href="/do-not-sell-my-info">
                <Text id="cookieConsent.doNotSellMyInfo" />
              </Link>
            </React.Fragment>
          )}
        </StyledCookieLinksContainer>
      )}
    </React.Fragment>
  );
};

const StyledCookieLinksContainer = styled.div({
  marginTop: 8,
  display: 'flex',
  justifyContent: 'flex-end',
  fontSize: 11,
  '> a': {
    color: '#ACACAC',
    marginLeft: 8,
    textDecoration: 'none',
  },
  '> a:visited': {
    color: '#A8A8A8',
  },
  '> a:hover': {
    color: '#0E4BC4',
  },
  '> span': {
    color: '#ACACAC',
    marginLeft: 8,
  },
});

export default CookieLinks;
