import * as React from 'react';
import { colors } from '@trustpilot/businessapp-patternlibrary';
import styled from '@emotion/styled';

interface Props {
  id?: string;
  children?: any;
  href?: string;
  target?: string;
  disabled?: boolean;
  onClick?: () => unknown;
}

const LinkComponent: React.FC<Props> = ({ id, children, href, target, onClick, disabled }) => {
  const handleOnClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <StyledLink id={id} href={href} target={target} onClick={handleOnClick} disabled={disabled}>
      {children}
    </StyledLink>
  );
};

const important = (style) => `${style} !important`;
const hoverColor = important('#2471b3');

export const LinkStyles = {
  color: important('#0C59F2'),
  textDecoration: important('none'),
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: hoverColor,
  },
  '&:visited': {
    color: important(colors.blue.hero),
  },
  '&:visited:hover,&:visited:focus': {
    color: hoverColor,
  },
};

const StyledLink: any = styled.a(LinkStyles, (props: Props) => ({
  ...(props.disabled
    ? {
        cursor: 'no-drop',
        color: important(colors.gray['40']),
        '&:hover,&:focus': {
          color: important(colors.gray['30']),
        },
      }
    : {}),
}));

export default LinkComponent;
