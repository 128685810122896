import * as tracking from '../../actions/tracking';
import { connect, ConnectedProps } from 'react-redux';
import { ActivationLinkExpiredComponent } from './activationLinkExpiredComponent';
import configuration from '../../configuration';

const mapDispatchToProps = {
  trackAndNavigate: tracking.trackAndNavigate,
};

const mapStateToProps = (state) => ({
  businessSignupUrl: configuration.BusinessSignupUrl,
  businessWebApp: state.activateAccount.locale?.businessWebApp,
  locale: state.activateAccount.locale?.locale,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ActivationLinkExpiredProps = ConnectedProps<typeof connector>;

const ActivationLinkExpiredContainer = connector(ActivationLinkExpiredComponent);

export default ActivationLinkExpiredContainer;
