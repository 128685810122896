import React, { useEffect, useState } from 'react';
import { StyledCard, StyledHeaderContainer, StyledInputFieldWrapper } from '../../components/commonElements';
import { Text, useTranslations } from 'ilenia';
import styled from '@emotion/styled';
import { LostDeviceProps } from '../../components/lost-device/lostDeviceContainer';
import LostDeviceSuccess from '../../components/lost-device/lostDeviceSuccess';
import BackToLoginLink from '../../components/back-to-login-link/backToLoginLink';
import { Button, Input, Typography } from '@trustpilot/businessapp-patternlibrary';

const LostDevice: React.FC<LostDeviceProps> = ({
  email,
  submitLostDevice,
  reset,
  error,
  inProgress,
  success,
  backToSecondFactor,
}) => {
  useEffect(() => {
    return () => reset();
  }, []);

  const [translations] = useTranslations();
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleEnterPress = () => {
    if (!inProgress) {
      submitLostDevice(email, phoneNumber);
    }
  };

  if (success) {
    return <LostDeviceSuccess email={email} />;
  }

  return (
    <StyledCard>
      <StyledHeaderContainer>
        <Typography variant="heading-m">
          <Text id="lostDevice.header" />
        </Typography>
      </StyledHeaderContainer>
      <StyledInformationTextContainer>
        <Typography>
          <Text id="lostDevice.information" />
        </Typography>
      </StyledInformationTextContainer>
      <StyledInputFieldWrapper marginBottom={24}>
        <Input
          id="phoneNumber"
          value={phoneNumber}
          placeholder={translations['lostDevice.inputPlaceholder']}
          onChange={(evt) => setPhoneNumber(evt.target.value)}
          onEnterPress={handleEnterPress}
          errorMessage={error && <Text id={`lostDevice.errors.${error}`} />}
        />
      </StyledInputFieldWrapper>
      <Button appearance="primary" stretch disabled={inProgress} onClick={() => submitLostDevice(email, phoneNumber)}>
        <Text id="lostDevice.continue" />
      </Button>
      {/* TODO seems like there was no link since the implementation */}
      {/*<LinksContainer>*/}
      {/*  <Link>*/}
      {/*    <Text id="lostDevice.needHelp" />*/}
      {/*  </Link>*/}
      {/*</LinksContainer>*/}
      <BackToLoginLink containerProps={{ marginTop: '32px' }} onClick={backToSecondFactor} />
    </StyledCard>
  );
};

const StyledInformationTextContainer = styled.div({
  marginBottom: 32,
});

export default LostDevice;
