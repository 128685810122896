import * as React from 'react';
import { useEffect, useState } from 'react';
import { NarrowPage } from '@trustpilot/businessapp-patternlibrary';
import HeaderComponent from '../../../components/header/headerComponent';
import { LoginProps } from '../../../components/pages/login/loginContainer';
import { useTranslations } from 'ilenia';
import { StyledCardContainer } from '../../../components/commonElements';
import CookieLinks from '../../../components/link/cookieLinks';
import { AuthenticationStep } from '../../../models/authenticationStep';
import LoginFormContainer from '../../../components/login-form/loginFormContainer';
import SecondFactorVerificationContainer from '../../../components/second-factor-verification/secondFactorVerificationContainer';
import firebase from '../../../firebase';
import LostDeviceContainer from '../../../components/lost-device/lostDeviceContainer';

export const Login: React.FC<LoginProps> = ({ initialize, initialized, authenticationStep }) => {
  const [translations] = useTranslations();
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (ref) {
      firebase.initRecaptchaVerifier();
    }
  }, [ref]);

  useEffect(() => {
    initialize();

    document.title = translations['login.title'];
  }, []);

  if (!initialized) {
    return null;
  }

  let Component: React.ElementType = LoginFormContainer;

  if (authenticationStep === AuthenticationStep.SECOND_FACTOR_VERIFICATION) {
    Component = SecondFactorVerificationContainer;
  } else if (authenticationStep === AuthenticationStep.LOST_DEVICE) {
    Component = LostDeviceContainer;
  }

  return (
    <React.Fragment>
      <HeaderComponent />
      <NarrowPage>
        <StyledCardContainer id="login-styled-page-container">
          <Component />
          <CookieLinks />
        </StyledCardContainer>
      </NarrowPage>
      <div id="recaptcha-container" ref={(ref) => setRef(ref)} />
    </React.Fragment>
  );
};
