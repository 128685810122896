export default {
  ApiUrl: 'https://authenticate.trustpilot.com',
  Host: '#{Host}',
  Port: '5000',
  FirebaseApiKey: 'AIzaSyDzj0FG4JS6bOUHLhbubOR_CVaH8EGSvO0',
  FirebaseAuthDomain: 'authentication.trustpilot.com',
  FirebaseSamlAuthDomain: 'authentication.trustpilot.com',
  FirebaseDatabaseURL: 'https://businessusers-auth-production.firebaseio.com',
  FirebaseMessagingSenderId: '458107167321',
  FirebaseProjectId: 'businessusers-auth-production',
  FirebaseStorageBucket: 'businessusers-auth-production.appspot.com',
  AnalyticsScriptUrl: '//cdn.trustpilot.net/stats-assets/production/tp-analytics.min.js',
  SegmentIoKey: 'ikt6azxah8',
  EnableRecaptcha: 'true',
  EnableSSOAuthentication: 'true',
  UrlParamsWhiteList: 'wix,square,zendeskappui',
  BusinessSignupUrl: 'https://business.trustpilot.com/signup',
  TrustpilotApiHost: 'https://api.trustpilot.com',
  PublicApiKey: '1AkoG9tdO4sG8x7njOUgVj7aA2wgHv2G',
  SocketUrl: '#(SocketUrl)',
};
