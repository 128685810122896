import { all, call, put, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/forgotPassword';
import * as Api from './api';

import { GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import { logError } from '../../sagas/error';

function* resetPassword(action) {
  try {
    yield put(actions.setInProgress(true));
    yield call(Api.resetPassword, action.payload);
    yield put(actions.success());
  } catch (error) {
    yield put(actions.setInProgress(false));
    GlobalAlertService.critical('Error occurred while reseting your password.', 3000);
    yield call(logError, error);
  }
}

export default function*() {
  yield all([takeLatest(matchesType(actions.resetPassword), resetPassword)]);
}
