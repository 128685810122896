import { Text, useTranslations } from 'ilenia';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  StyledCard,
  StyledHeader,
  StyledInput,
  StyledInputFieldWrapper,
  StyledInputFloatingLabel,
} from '../commonElements';
import { ResetLostDeviceProps } from '../../components/reset-lost-device/resetLostDeviceContainer';
import styled from '@emotion/styled';
import ResetLostDeviceSuccess from '../../components/reset-lost-device/resetLostDeviceSuccess';
import { Button } from '@trustpilot/businessapp-patternlibrary';

const ResetLostDevice: React.FC<ResetLostDeviceProps> = ({
  token,
  inProgress,
  resetLostDevice,
  success,
  initialize,
  initialized,
  resetLostDeviceInformation,
}) => {
  const [translations] = useTranslations();
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (token && !initialized) {
      initialize(token);
    }
  }, [token, initialized]);

  const handleResetLostDevice = () => {
    resetLostDevice(token, password, resetLostDeviceInformation.email);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && password) {
      handleResetLostDevice();
    }
  };

  if (success) {
    return <ResetLostDeviceSuccess />;
  }

  if (!initialized) {
    return null;
  }

  return (
    <StyledCard>
      <StyledHeader>
        <Text id="resetLostDevice.header" />
      </StyledHeader>
      <StyledInformationText>
        <Text id="resetLostDevice.information" />
      </StyledInformationText>
      <StyledInputFieldWrapper marginBottom={24}>
        <StyledInputFloatingLabel isUp={password ? true : isPasswordFocused} topLabelUp={8} top={16}>
          {translations['resetLostDevice.enterPassword']}
        </StyledInputFloatingLabel>
        <StyledInput
          id="password"
          value={password}
          type="password"
          dotsEnabled={false}
          onChange={(evt) => setPassword(evt.target.value)}
          onKeyPress={(event) => handleKeyPress(event)}
          onFocus={() => setIsPasswordFocused(true)}
          onBlur={() => setIsPasswordFocused(false)}
          notEmpty={password ? true : isPasswordFocused}
          disabled={inProgress}
        />
      </StyledInputFieldWrapper>
      <Button disabled={inProgress || !password} onClick={handleResetLostDevice}>
        <Text id="resetLostDevice.removeNow" />
      </Button>
    </StyledCard>
  );
};

const StyledInformationText = styled.div({
  marginBottom: 32,
  fontSize: 16,
  lineHeight: '24px',
});

export default ResetLostDevice;
