import axios from 'axios';
import Config from '../../../app/configuration';
import { SsoConfigurationResponse } from '../../models/ssoConfigurationResponse';
import { BusinessUnit } from 'app/models/BusinessUnit';

export async function getSsoConfiguration(domain: string): Promise<SsoConfigurationResponse> {
  try {
    const response = await axios.get(`${Config.ApiUrl}/sso-configuration?domain=${domain}`);

    return response.data;
  } catch (e) {
    if (e.response.status === 404) {
      return null;
    }

    throw e;
  }
}

export async function getUnitIdForDomainName(domain: string): Promise<BusinessUnit> {
  try {
    const response = await axios.get(
      `${Config.TrustpilotApiHost}/v1/business-units/find?name=${domain}&apikey=${Config.PublicApiKey}`,
    );

    return response.data.id;
  } catch (e) {
    if (e.response.status === 404) {
      return null;
    }

    throw e;
  }
}
