import { createActions } from 'redux-actions';

export const {
  secondFactorVerification: {
    initialize,
    setInitialized,
    setResolver,
    submitVerificationCode,
    setVerificationId,
    setInProgress,
    setError,
    resendCode,
    setResendCodeCountdown,
    initializationFailed,
  },
}: any = createActions({
  SECOND_FACTOR_VERIFICATION: {
    INITIALIZE: (resolver, email, queryString, phoneNumber) => ({ resolver, email, queryString, phoneNumber }),
    SET_INITIALIZED: null,
    SET_RESOLVER: null,
    SUBMIT_VERIFICATION_CODE: null,
    SET_VERIFICATION_ID: null,
    SET_IN_PROGRESS: null,
    SET_ERROR: null,
    RESEND_CODE: null,
    SET_RESEND_CODE_COUNTDOWN: null,
    INITIALIZATION_FAILED: null,
  },
});
