import axios from 'axios';
import Config from '../../../app/configuration';

export async function startVerifyPhoneNumber(secret, recaptchaToken) {
  try {
    const response = await axios.post(`${Config.ApiUrl}/start-verify-phone-number`, {
      secret,
      recaptchaToken,
    });

    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      return e.response.data;
    }

    throw e;
  }
}

export async function finalizeVerifyPhoneNumber(sessionInfo, code) {
  try {
    const response = await axios.post(`${Config.ApiUrl}/finalize-verify-phone-number`, {
      sessionInfo,
      code,
    });

    return response.data;
  } catch (e) {
    if (e.response.status === 401) {
      return e.response.data;
    }

    throw e;
  }
}
