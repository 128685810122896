import LostDevice from '../../components/lost-device/lostDevice';
import * as actions from '../../actions/lostDevice';
import { connect, ConnectedProps } from 'react-redux';

const mapDispatchToProps = {
  submitLostDevice: actions.submitLostDevice,
  reset: actions.reset,
  backToSecondFactor: actions.backToSecondFactor,
};

const mapStateToProps = (state) => ({
  success: state.lostDevice.success,
  email: state.lostDevice.email,
  error: state.lostDevice.error,
  inProgress: state.lostDevice.inProgress,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LostDeviceProps = ConnectedProps<typeof connector>;

const LostDeviceContainer = connector(LostDevice);

export default LostDeviceContainer;
