export const BUSINESSUNIT_COOKIE = 'tp-b2b-selected-business-unit-id';
export const ISSSOAUTHENTICATED_COOKIE = 'tp-b2b-is-sso-saml-authenticated';
export const SSOAUTHENTICATED_BUSINESSACCOUNTID_COOKIE = 'tp-b2b-sso-saml-authenticated-business-account-id';
export const SSOAUTHENTICATED_RECENTLY_COOKIE = 'tp-b2b-sso-saml-recently-authenticated';

// note: we're not using @trustpilot/businessapp-browserstorage because cookies aren't on OneTrust whitelist yet
function setCookie(name: string, value: string, url: Location, days?: number) {
  const domain = determineCookieDomain(url);
  document.cookie = `${name}=${value}; path=/; max-age=${60 * 60 * 24 * days}; domain=${domain}`;
}

export function setBusinessUnitCookie(businessUnitId: string, url: Location) {
  setCookie(BUSINESSUNIT_COOKIE, businessUnitId, url, 365);
}

export function setIsSsoAuthenticatedCookie(isSsoAuthenticated: boolean, url: Location) {
  setCookie(ISSSOAUTHENTICATED_COOKIE, isSsoAuthenticated.toString(), url, 365);
}

export function setSsoAuthenticatedBusinessAccountCookie(businessAccountId: string, url: Location) {
  setCookie(SSOAUTHENTICATED_BUSINESSACCOUNTID_COOKIE, businessAccountId, url, 1);
}

export function setSsoRecentlyAuthenticatedCookie(isSsoRecentlyAuthenticated: boolean, url: Location) {
  setCookie(SSOAUTHENTICATED_RECENTLY_COOKIE, isSsoRecentlyAuthenticated.toString(), url, 1);
}

const determineCookieDomain = (url: Location): string => {
  // Return null for localhost so it can be omitted
  if (url.host.match(/localhost/)) {
    return null;
  }

  // For other domains, the domain must contain exactly two dots
  // Example: .trustpilot.com / .tp-local.com
  // This takes off any port, takes the last two parts of the host (split by dot)
  // and puts them together again
  const parts = url.host.replace(/:\d{4}/, '').split('.');
  return `.${parts.filter((a, i) => i >= parts.length - 2).join('.')}`;
};
