import { handleActions } from 'redux-actions';
import * as Actions from '../actions/app';

const initialState = {
  initialized: false,
  locale: 'en-US',
};

export const appReducer = handleActions<any>(
  {
    [Actions.setInitialized]: (state, action) => {
      return { ...state, initialized: action.payload };
    },
    [Actions.setLocale]: (state, action) => {
      return { ...state, locale: action.payload };
    },
  },
  initialState,
);
