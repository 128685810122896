import React from 'react';
import { SecondFactorVerificationProps } from '../../components/second-factor-verification/secondFactorVerificationContainer';
import { StyledCard } from '../../components/commonElements';
import Loading from '../../components/loading';
import SecondFactorCodeForm from '../../components/second-factor-code-form/secondFactorCodeForm';

const SecondFactorVerification: React.FC<SecondFactorVerificationProps> = ({
  submitVerificationCode,
  phoneNumber,
  initialized,
  error,
  inProgress,
  resendCode,
  resendCodeCountdown,
  lostDevice,
  email,
}) => {
  return (
    <StyledCard>
      {!initialized && <Loading />}
      {initialized && (
        <SecondFactorCodeForm
          submitVerificationCode={submitVerificationCode}
          inProgress={inProgress}
          phoneNumber={phoneNumber}
          resendCode={resendCode}
          resendCodeCountdown={resendCodeCountdown}
          error={error}
          lostDeviceEnabled
          lostDevice={lostDevice}
          email={email}
        />
      )}
    </StyledCard>
  );
};

export default SecondFactorVerification;
