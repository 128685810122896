import { createActions } from 'redux-actions';

export const {
  changePassword: {
    initialize,
    setInitialized,
    setSuccess,
    setInProgress,
    setChangePasswordData,
    setLocale,
    setSecret,
    setPassword,
    changePassword,
    setErrorCode,
    setSecondFactorToken,
  },
}: any = createActions({
  CHANGE_PASSWORD: {
    INITIALIZE: null,
    SET_INITIALIZED: null,
    SET_SUCCESS: null,
    SET_IN_PROGRESS: null,
    SET_CHANGE_PASSWORD_DATA: null,
    SET_LOCALE: null,
    SET_SECRET: null,
    SET_PASSWORD: null,
    CHANGE_PASSWORD: null,
    SET_ERROR_CODE: null,
    SET_SECOND_FACTOR_TOKEN: null,
  },
});
