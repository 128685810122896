import { all, call, cancel, cancelled, delay, put, select, spawn, takeLatest } from 'redux-saga/effects';
import * as actions from '../../actions/changePasswordSecondFactorFormActions';
import { matchesType } from '../../sagas/helpers';
import firebase from '../../firebase';
import { logError } from '../error';
import { GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import * as api from './api';

let timerTask = null;

function* initialize(action) {
  try {
    const { secret } = action.payload;

    yield call(firebase.initRecaptchaVerifier.bind(firebase));
    const recaptchaToken = yield call(firebase.executeRecaptchaVerifier.bind(firebase));

    yield call(startVerifyPhoneNumber, secret, recaptchaToken);

    yield put(actions.setInitialized(true));
  } catch (error) {
    if (error.response && error.response.data.error) {
      yield put(actions.setError(error.response.data.error));
    }

    GlobalAlertService.critical('Sorry, something went wrong. Please try again.', 3000);
    yield call(logError, error);
  }
}

function* startVerifyPhoneNumber(secret, recaptchaToken) {
  try {
    const response = yield call(api.startVerifyPhoneNumber, secret, recaptchaToken);

    yield put(actions.setSessionInfo(response.sessionInfo));
    yield put(actions.setResendCodeCountdown(60));

    if (!timerTask) {
      timerTask = yield spawn(startResendCodeCountdown);
    }
  } catch (error) {
    if (error.response && error.response.data.error) {
      yield put(actions.setError(error.response.data.error));
    }

    GlobalAlertService.critical('Sorry, something went wrong. Please try again.', 3000);
    yield call(logError, error);
  }
}

function* submitVerificationCode(action) {
  try {
    yield put(actions.setInProgress(true));
    yield put(actions.setError(null));

    const code = action.payload;
    const sessionInfo = yield select((state) => state.changePasswordSecondFactorForm.sessionInfo);

    const response = yield call(api.finalizeVerifyPhoneNumber, sessionInfo, code);

    yield put(actions.secondFactorVerificationSuccess(response.idToken));
  } catch (error) {
    if (error.response && error.response.data.error === 'INVALID_CODE') {
      yield put(actions.setError('invalidVerificationCode'));
    } else {
      GlobalAlertService.critical('Error occurred while verifying the code', 3000);
      yield logError(error);
    }

    yield put(actions.secondFactorVerificationError());
    yield put(actions.setInProgress(false));
  }
}

function* resendCode(action) {
  try {
    const { secret } = action.payload;

    yield put(actions.setInProgress(true));

    yield call(firebase.clearRecaptcha.bind(firebase));
    yield call(firebase.initRecaptchaVerifier.bind(firebase));
    const recaptchaToken = yield call(firebase.executeRecaptchaVerifier.bind(firebase));

    yield call(startVerifyPhoneNumber, secret, recaptchaToken);
  } catch (e) {
    yield call(logError, e);
  } finally {
    yield put(actions.setInProgress(false));
  }
}

function* startResendCodeCountdown() {
  let resendCodeCountdown;

  while (!(yield cancelled())) {
    yield delay(1000);

    resendCodeCountdown = yield select((state) => state.changePasswordSecondFactorForm.resendCodeCountdown);
    if (resendCodeCountdown > 0) {
      yield put(actions.setResendCodeCountdown(resendCodeCountdown - 1));
    }
  }
}

function* reset() {
  try {
    yield cancel(timerTask);
  } catch (error) {
    yield call(logError, error);
  }
}

export default function*() {
  yield all([
    takeLatest(matchesType(actions.initialize), initialize),
    takeLatest(matchesType(actions.submitVerificationCode), submitVerificationCode),
    takeLatest(matchesType(actions.resendCode), resendCode),
    takeLatest(matchesType(actions.reset), reset),
  ]);
}
