import { Text, useTranslations } from 'ilenia';
import { Card, BannerAlert, Input, Button } from '@trustpilot/businessapp-patternlibrary';
import * as React from 'react';
import Loading from '../../components/loading';
import { StyledHeader, StyledInputFieldWrapper } from '../commonElements';
import { LinkLoginProps } from './linkLoginFormContainer';
import { AuthenticationErrorMessage } from '../../models/authenticationError';
import styled from '@emotion/styled';

const StyledLoginButton = styled(Button)({
  fontWeight: 'normal',
});

export const LoginForm: React.FC<LinkLoginProps> = ({
  authenticate,
  authenticationErrorCode,
  authenticationErrorMessage,
  emailRequired,
}) => {
  const [translations] = useTranslations();
  const [email, setEmail] = React.useState('');
  const authenticationErrorTranslated = AuthenticationErrorMessage[authenticationErrorCode];

  const inProgress = !emailRequired && !authenticationErrorTranslated && !authenticationErrorMessage;

  const handleEnterPress = () => {
    if (email) {
      authenticate(email, window.location.href);
    }
  };

  return (
    <Card>
      <StyledHeader>
        <Text id="login.header" />
      </StyledHeader>
      {(authenticationErrorTranslated || authenticationErrorMessage) && (
        <StyledBannerAlertContainer>
          <BannerAlert appearance="critical">
            {authenticationErrorTranslated && <Text id={authenticationErrorTranslated} />}
            {authenticationErrorMessage && authenticationErrorMessage}
          </BannerAlert>
        </StyledBannerAlertContainer>
      )}
      {emailRequired && (
        <>
          <StyledInputFieldWrapper marginBottom={16}>
            <Input
              id="email"
              name="email"
              value={email}
              placeholder={translations['common.workEmail']}
              onChange={(evt) => setEmail(evt.target.value)}
              onEnterPress={handleEnterPress}
              disabled={inProgress}
            />
          </StyledInputFieldWrapper>
          <StyledLoginButton
            disabled={!email}
            onClick={() => authenticate(email, window.location.href)}
            appearance="primary"
            size="large"
            stretch
            data-testid="login"
          >
            <Text id="login.logIn" />
          </StyledLoginButton>
        </>
      )}
      {inProgress && <Loading />}
    </Card>
  );
};

const StyledBannerAlertContainer = styled.div({
  marginBottom: 24,
});
