import { BannerAlert, Button } from '@trustpilot/businessapp-patternlibrary';
import { Text } from 'ilenia';
import * as React from 'react';
import { Spacer32, StyledButtonLink } from '../commonElements';

const ChangePasswordExpired = ({ businessWebApp }) => {
  return (
    <>
      <BannerAlert appearance="info">
        <Text id="common.activationLinkExpired" />
      </BannerAlert>
      <Spacer32 />
      <StyledButtonLink href={businessWebApp}>
        <Button stretch size="large" appearance="primary">
          <Text id="common.goToLogin" />
        </Button>
      </StyledButtonLink>
    </>
  );
};

export default ChangePasswordExpired;
