import * as React from 'react';
import { useEffect } from 'react';
import { Card, NarrowPage } from '@trustpilot/businessapp-patternlibrary';
import Loading from '../../../components/loading';
import { ActivateAccountProps } from '../../../components/pages/activate-account/activateAccountContainer';
import { ActivationState } from '../../../models/activationState';
import CompleteActivationContainer from '../../../components/complete-activation/completeActivationContainer';
import ActivationLinkExpiredContainer from '../../../components/activation-link-expired/activationLinkExpiredContainer';
import HeaderComponent from '../../..//components/header/headerComponent';
import { useTranslations } from 'ilenia';
import { StyledCardContainer } from '../../../components/commonElements';
import CookieLinks from '../../../components/link/cookieLinks';
import { Routes } from '../../../models/routes';

export const ActivateAccount: React.FC<ActivateAccountProps> = ({
  verify,
  activationState,
  initialized,
  initialize,
}) => {
  const [translations] = useTranslations();

  useEffect(() => {
    document.title = translations['completeActivation.title'];

    if (!initialized) {
      initialize();
    }

    if (initialized) {
      verify();
    }
  }, [initialized]);

  useEffect(() => {
    if (activationState === ActivationState.ACTIVATED) {
      window.location.href = Routes.LOGIN;
    } else if (activationState === ActivationState.SSO_ACTIVATED) {
      window.location.href = Routes.SSO_LOGIN;
    }
  }, [activationState]);

  let component = (
    <Card css={{ width: '100%' }}>
      <Loading />
    </Card>
  );

  if (!initialized) {
    return null;
  }

  let maxWidth = 560;
  if (activationState === ActivationState.OUTSTANDING) {
    component = <CompleteActivationContainer />;
  } else if (activationState === ActivationState.EXPIRED) {
    component = <ActivationLinkExpiredContainer />;
    maxWidth = 405;
  }

  return (
    <React.Fragment>
      <HeaderComponent />
      <NarrowPage>
        <StyledCardContainer id="styled-card-container" maxWidth={maxWidth}>
          {component}
          <CookieLinks />
        </StyledCardContainer>
      </NarrowPage>
    </React.Fragment>
  );
};
