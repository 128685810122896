export const matchesType = (actionCreator) => (currentAction) => {
  return actionCreator().type === currentAction.type;
};

const signInEmailKey = 'SIGN_IN_EMAIL';
export const addSignEmailToLocalStorage = (email) => {
  window.localStorage.setItem(signInEmailKey, email);
};

export const getSignInEmailFromLocalStorage = () => {
  return window.localStorage.getItem(signInEmailKey);
};

export const removeSignInEmailFromLocalStorage = () => {
  window.localStorage.removeItem(signInEmailKey);
};
