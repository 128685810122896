import { createActions } from 'redux-actions';

export const {
  forgotPassword: { setEmail, resetPassword, setInProgress, success },
}: any = createActions({
  FORGOT_PASSWORD: {
    SET_EMAIL: null,
    RESET_PASSWORD: null,
    SET_IN_PROGRESS: null,
    SUCCESS: null,
  },
});
