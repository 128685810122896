import { createActions } from 'redux-actions';

export const {
  ssoLogin: { initialize, setInitialized, setDomain, login, setLoginProvider, setInProgress, setSuccess, setFailed },
}: any = createActions({
  SSO_LOGIN: {
    INITIALIZE: null,
    SET_INITIALIZED: null,
    SET_DOMAIN: null,
    LOGIN: null,
    SET_LOGIN_PROVIDER: null,
    SET_IN_PROGRESS: null,
    SET_SUCCESS: null,
    SET_FAILED: null,
  },
});
