import { createActions } from 'redux-actions';

export const {
  activateAccount: {
    verify,
    verified,
    setPassword,
    setName,
    showPassword,
    hidePassword,
    activateAccount,
    acceptTermsAndConditions,
    setLocale,
    setActivationState,
    initialize,
    setInitialized,
    setInProgress,
  },
}: any = createActions({
  ACTIVATE_ACCOUNT: {
    VERIFY: null,
    VERIFIED: null,
    SET_PASSWORD: null,
    SET_NAME: null,
    SHOW_PASSWORD: null,
    HIDE_PASSWORD: null,
    ACCEPT_TERMS_AND_CONDITIONS: null,
    ACTIVATE_ACCOUNT: null,
    SET_LOCALE: null,
    SET_ACTIVATION_STATE: null,
    INITIALIZE: null,
    SET_INITIALIZED: null,
    SET_IN_PROGRESS: null,
  },
});
