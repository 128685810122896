import axios from 'axios';
import Config from '../configuration';
import { Locale } from '../models/locale';
import { AuthenticationResponse } from '../models/authenticationResponse';

export async function getLocale(locale: string): Promise<Locale> {
  const response = await axios.get(`${Config.ApiUrl}/resources/locale?locale=${locale}&v=2`);

  return response.data;
}

export async function googleAuthentication(token: string, googleToken: string): Promise<AuthenticationResponse> {
  const query = window.location.search.substring(1) + '&v=2';
  const response = await axios.post(`${Config.ApiUrl}/google-authentication?${query}`, {
    token,
    googleToken,
  });

  return response.data;
}

export async function ssoAuthentication(
  token: string,
  username: string,
  providerId: string,
): Promise<AuthenticationResponse> {
  const query = window.location.search.substring(1) + '&v=2';
  const response = await axios.post(`${Config.ApiUrl}/sso-authentication?${query}`, {
    token,
    username,
    providerId,
  });

  return response.data;
}

export default {
  getLocale,
  googleAuthentication,
  ssoAuthentication,
};
