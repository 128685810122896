import * as React from 'react';
import { Text } from 'ilenia';
import { ActivationLinkExpiredProps } from './activationLinkExpiredContainer';
import styled from '@emotion/styled';
import { Link, Typography } from '@trustpilot/businessapp-patternlibrary';

export const ActivationLinkExpiredComponent: React.FC<ActivationLinkExpiredProps> = ({
  trackAndNavigate,
  businessSignupUrl,
  businessWebApp,
  locale,
}) => {
  const handleSignupLinkClick = () => {
    let flow = null;
    const queryString = window.location.search;

    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      flow = queryParams.get('flow');
    }

    if (flow) {
      businessSignupUrl = `${businessSignupUrl}?flow=${flow}&locale=${locale}`;
    } else {
      businessSignupUrl = `${businessSignupUrl}?locale=${locale}`;
    }

    const url = `${businessSignupUrl}&utm_medium=business_login_page&utm_source=sign_up_link&utm_campaign=activation_expired`;
    const isInIFrame = window.self !== window.top;
    const eventData = {
      LinkPosition: 'ActivationLinkExpired',
      isInIFrame,
    };
    const target = isInIFrame ? '_blank' : null;

    trackAndNavigate('SignUpLinkClickedLoginPage', eventData, url, null, target);
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <Text id="common.activationLinkExpired" />
      </StyledHeader>
      <StyledButtonLink href={businessWebApp}>
        <StyledButtonContainer>
          <Text id="common.goToLogin" />
        </StyledButtonContainer>
      </StyledButtonLink>
      <FooterContainer>
        <Typography variant="body-m">
          <Text
            id="login.dontHaveAccount"
            interpolations={{
              LINK: (content) => <Link onClick={handleSignupLinkClick}>{content}</Link>,
            }}
            suffix={{ begin: '-BEGIN', end: '-END' }}
          />
        </Typography>
      </FooterContainer>
    </StyledContainer>
  );
};

const StyledButtonContainer = styled.div({
  marginBottom: '32px',
});

const StyledHeader = styled.div({
  textAlign: 'center',
  fontSize: 27,
  marginTop: 3.2,
  marginBottom: 8,
  padding: 0,
  lineHeight: 1.4,
});

const StyledContainer = styled.div({
  maxWidth: 405,
  backgroundColor: '#FFF',
  padding: '2rem 2.6rem 2rem',
});

const FooterContainer = styled.div({
  fontSize: 12,
  fontFamily: 'sans-serif',
});

const StyledButtonLink = styled.a({
  textDecoration: 'none',
});
