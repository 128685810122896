import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/changePasswordActions';
import * as trackingActions from '../../actions/tracking';
import * as changePasswordSecondFactorFormActions from '../../actions/changePasswordSecondFactorFormActions';
import * as Api from './api';
import * as commonApi from '../commonApi';
import { GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import { logError } from '../../sagas/error';
import * as qs from 'qs';

function* initialize(action) {
  try {
    const query = window.location.search.substr(1);
    const localeParameter = qs.parse(query).locale as string;
    const secret = action.payload;

    const [changePasswordData, locale] = yield all([
      call(Api.getChangePasswordData, secret),
      call(commonApi.getLocale, localeParameter),
    ]);

    yield put(actions.setSecret(secret));
    yield put(actions.setChangePasswordData(changePasswordData));
    yield put(actions.setLocale(locale));
    yield put(actions.setInitialized(true));
  } catch (error) {
    GlobalAlertService.critical('Error occurred while initializing change password page', 3000);
    yield call(logError, error);
  }
}

function* changePassword() {
  const { secret, password, secondFactorToken, changePasswordData } = yield select((state) => state.changePassword);
  const flow = changePasswordData.changeEmailFlow ? 'change-email' : 'change-password';

  try {
    yield put(actions.setInProgress(true));
    yield call(Api.changePassword, secret, password, secondFactorToken);

    yield call(trackChangePasswordButtonClick, true, flow);

    yield put(actions.setSuccess(true));
  } catch (error) {
    yield call(trackChangePasswordButtonClick, false, flow);
    const errorCode = error.response?.data?.errorCode;

    if (errorCode === 1004 || errorCode === 1005) {
      yield put(actions.setErrorCode(errorCode));
    } else {
      yield call(logError, error);
      GlobalAlertService.critical('Error occurred while changing password', 3000);
    }

    yield put(actions.setInProgress(false));
  }
}

function* onSecondFactorVerificationSuccess(action) {
  try {
    yield call(trackSecondFactorVerificationCodeSubmit, true);
    yield put(actions.setSecondFactorToken(action.payload));
  } catch (error) {
    yield call(logError, error);
  }
}

function* onSecondFactorVerificationError() {
  try {
    yield call(trackSecondFactorVerificationCodeSubmit, false);
  } catch (error) {
    yield call(logError, error);
  }
}

function* trackSecondFactorVerificationCodeSubmit(success) {
  try {
    const { changePasswordData } = yield select((state) => state.changePassword);

    const flow = changePasswordData.changeEmailFlow
      ? 'change-email-submit-verification-code'
      : 'change-password-submit-verification-code';

    yield call(trackChangePasswordButtonClick, success, flow);
  } catch (error) {
    yield call(logError, error);
  }
}

function* trackChangePasswordButtonClick(success, flow) {
  try {
    const { changePasswordData } = yield select((state) => state.changePassword);

    yield put(
      trackingActions.track(
        'Button Clicked',
        {
          action: 'Submit',
          context: 'businessauthentication-businessapp',
          flow,
          location: '/changePassword',
          success,
        },
        changePasswordData.newEmail || changePasswordData.email,
      ),
    );
  } catch (error) {
    yield call(logError, error);
  }
}

export default function*() {
  yield all([
    takeLatest(matchesType(actions.initialize), initialize),
    takeLatest(matchesType(actions.changePassword), changePassword),
    takeLatest(
      matchesType(changePasswordSecondFactorFormActions.secondFactorVerificationSuccess),
      onSecondFactorVerificationSuccess,
    ),
    takeLatest(
      matchesType(changePasswordSecondFactorFormActions.secondFactorVerificationError),
      onSecondFactorVerificationError,
    ),
  ]);
}
