import { handleActions } from 'redux-actions';
import * as Actions from '../actions/activateAccountActions';

const initialState = {
  password: '',
  name: '',
  characterCountRule: false,
  lowercaseCharacterRule: false,
  uppercaseCharacterRule: false,
  containsNumberCharacterRule: false,
  allRulesMatch: false,
  passwordVisible: false,
  termsAndConditionsAccepted: false,
  activationSecret: null,
  activationSource: null,
  businessUser: null,
  queryString: null,
  locale: null,
  activationState: null,
  activationInProgress: false,
};

const checkPasswordRules = (password: string) => {
  const rules = {
    characterCountRule: password.length >= 8,
    lowercaseCharacterRule: /[a-z]/.test(password),
    uppercaseCharacterRule: /[A-Z]/.test(password),
    containsNumberCharacterRule: /\d/.test(password),
  };
  return { ...rules, allRulesMatch: Object.keys(rules).every((i) => rules[i]) };
};

export const activateAccountReducer = handleActions<any>(
  {
    [Actions.activateAccount]: (state) => {
      return { ...state };
    },
    [Actions.setInProgress]: (state, action) => {
      return { ...state, activationInProgress: action.payload };
    },
    [Actions.setPassword]: (state, action) => {
      return { ...state, password: action.payload, ...checkPasswordRules(action.payload) };
    },
    [Actions.setName]: (state, action) => {
      return { ...state, name: action.payload };
    },
    [Actions.showPassword]: (state, action) => {
      return { ...state, passwordVisible: action.payload };
    },
    [Actions.acceptTermsAndConditions]: (state, action) => {
      return { ...state, termsAndConditionsAccepted: action.payload };
    },
    [Actions.verified]: (state, action) => {
      return { ...state, ...action.payload };
    },
    [Actions.setLocale]: (state, action) => {
      return { ...state, locale: action.payload };
    },
    [Actions.setActivationState]: (state, action) => {
      return { ...state, activationState: action.payload };
    },
    [Actions.setInitialized]: (state, action) => {
      return { ...state, initialized: action.payload };
    },
  },
  initialState,
);
