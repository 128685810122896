import * as React from 'react';
import { TrustpilotLogo } from '@trustpilot/businessapp-patternlibrary';

export const HeaderComponent = () => {
  return (
    <div style={{ backgroundColor: 'var(--trustpilot-brand-green-10)', padding: '13px 0px 12px 20px' }}>
      <TrustpilotLogo theme="dark" />
    </div>
  );
};


export default HeaderComponent;
