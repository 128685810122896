import { createActions } from 'redux-actions';

export const {
  changePasswordSecondFactorForm: {
    initialize,
    setInitialized,
    setInProgress,
    submitVerificationCode,
    resendCode,
    setError,
    secondFactorVerificationSuccess,
    secondFactorVerificationError,
    setPhoneNumber,
    setResendCodeCountdown,
    setSessionInfo,
    reset,
  },
}: any = createActions({
  CHANGE_PASSWORD_SECOND_FACTOR_FORM: {
    INITIALIZE: (secret, recaptchaToken) => ({ secret, recaptchaToken }),
    SET_INITIALIZED: null,
    SET_IN_PROGRESS: null,
    SUBMIT_VERIFICATION_CODE: null,
    RESEND_CODE: (secret) => ({ secret }),
    SET_ERROR: null,
    SECOND_FACTOR_VERIFICATION_SUCCESS: null,
    SECOND_FACTOR_VERIFICATION_ERROR: null,
    SET_PHONE_NUMBER: null,
    SET_RESEND_CODE_COUNTDOWN: null,
    SET_SESSION_INFO: null,
    RESET: null,
  },
});
