import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/loginForm';
import * as loginActions from '../../actions/login';
import * as trackingActions from '../../actions/tracking';
import * as secondFactorVerificationActions from '../../actions/secondFactorVerification';
import firebase, { Firebase } from '../../firebase';
import { logError } from '../../sagas/error';
import { AuthenticationError } from '../../models/authenticationError';
import { trackAndNavigate } from '../../actions/tracking';
import Config from '../../../app/configuration';
import configuration from '../../../app/configuration';
import qs from 'qs';
import { UtmParams } from '../../models/utmParams';

function setLanguage(firebase: Firebase) {
  const locale = window.location.search.match(/locale=([^&|?]+)/);

  firebase.setLanguageCode(locale && locale.length > 1 ? locale[1] : 'en');
}

function getResponseType(): string {
  const responseType = window.location.search.match(/response_type=([^&|?]+)/);

  if (responseType && responseType.length > 1) {
    return responseType[1];
  }

  return '';
}

function isValidResponseType() {
  try {
    const responseType = getResponseType();

    if (responseType !== '') {
      return responseType === 'code' || responseType === 'token';
    }
  } catch (e) {
    return false;
  }

  return true;
}

function* authenticate() {
  yield put(actions.setAuthenticateInProgress(true));

  const { password, email } = yield select((state) => state.loginForm);
  const search = window.location.search;
  const query = search.substr(1);

  try {
    yield signInWithEmailAndPassword(email, password, query);
  } catch (error) {
    if (error.code === 'auth/multi-factor-auth-required') {
      const resolver = firebase.getMultiFactorResolver(error);
      const {
        customData: {
          _serverResponse: { mfaInfo },
        },
      } = error;
      yield put(secondFactorVerificationActions.initialize(resolver, email, query, mfaInfo[0].phoneInfo));
    } else if (error.code === 'auth/user-disabled') {
      yield put(actions.setAuthenticateInProgress(false));
      yield put(actions.setAuthenticationErrorCode(AuthenticationError.INACTIVE_USER));
      return;
    } else {
      yield put(actions.setAuthenticateInProgress(false));
      yield processFailedLoginAttempt(error, email);
      if (isInvalidCredentials(error.code)) {
        yield put(actions.setAuthenticationErrorCode(AuthenticationError.INVALID_CREDENTIALS));
        return;
      }
      yield put(actions.setAuthenticationErrorMessage(error.message));
      yield call(logError, error);
    }
  }
}

function* processFailedLoginAttempt(error, email) {
  if (error.code === 'auth/wrong-password' || error.code === 'auth/too-many-requests') {
    return;
  }

  const responseType = getResponseType();

  yield put(
    trackingActions.trackLoginAttempt({
      email,
      responseType,
      provider: trackingActions.Providers.PASSWORD,
      loginSuccess: false,
    }),
  );
}

function* authenticateWithGoogle() {
  try {
    yield put(actions.setGoogleAuthenticateInProgress(true));

    const validResponseType = yield call(isValidResponseType);

    if (!validResponseType) {
      yield put(actions.setAuthenticationErrorCode(AuthenticationError.UNSUPPORTED_RESPONSE_TYPE));
      return;
    }

    window.sessionStorage.setItem('pendingRedirect', 'true');
    yield call(setLanguage, firebase);
    yield call(firebase.authenticateWithGoogle);
  } catch (e) {
    yield put(actions.setGoogleAuthenticateInProgress(false));
    yield call(logError, e);
  }
}

export function* signInWithEmailAndPassword(email: string, password: string, search: string) {
  yield call(firebase.signInWithEmailAndPassword, email, password);
  yield put(loginActions.verifyCurrentUserAndRedirect(email, search));
}

function isInvalidCredentials(errorCode) {
  return (
    errorCode === 'auth/wrong-password' || errorCode === 'auth/user-not-found' || errorCode === 'auth/invalid-email'
  );
}

function* onClickSignup() {
  const locale = yield select((state) => state.login.locale.locale);
  const queryParams = new URLSearchParams(window.location.search);
  const utmParams = getUtmParams(queryParams) || {
    utm_medium: 'business_login_page',
    utm_source: 'sign_up_link',
    utm_campaign: 'login',
  };
  const flow = queryParams.get('flow');
  const integrationConfig = queryParams.get('integration_config');
  const domain = queryParams.get('domain');
  const company = queryParams.get('company');
  const email = queryParams.get('email');
  const phone = queryParams.get('phone');
  const country = queryParams.get('country');
  const firstName = queryParams.get('firstName');
  const lastName = queryParams.get('lastName');
  const emailLocked = queryParams.get('emailLocked');
  const source = queryParams.get('source');
  const params = {
    ...utmParams,
    flow,
    locale,
    integration_config: integrationConfig,
    source,
    domain,
    company,
    email,
    phone,
    country,
    firstName,
    lastName,
    emailLocked,
  };

  const businessSignupUrl = `${configuration.BusinessSignupUrl}?${qs.stringify(params, {
    skipNulls: true,
  })}`;

  const isInIFrame = window.self !== window.top;
  const eventData = {
    LinkPosition: 'BottomLeft',
    isInIFrame,
  };
  const target = isInIFrame ? '_blank' : null;

  yield put(trackAndNavigate('SignUpLinkClickedLoginPage', eventData, businessSignupUrl, null, target));
}

export const getUtmParams = (queryParams: URLSearchParams): UtmParams => {
  if (!queryParams) return null;

  const redirectUriString = queryParams.get('redirect_uri');
  let utmParams: UtmParams = null;

  if (redirectUriString) {
    const redirectUrl = new URL(redirectUriString);
    const redirectUrlParams = new URLSearchParams(redirectUrl.search);
    utmParams = getUtmParams(redirectUrlParams);
  }

  if (Config.UrlParamsWhiteList.split(',').includes(queryParams.get('utm_source'))) {
    utmParams = {
      utm_medium: utmParams?.utm_medium || queryParams.get('utm_medium'),
      utm_source: utmParams?.utm_source || queryParams.get('utm_source'),
      utm_campaign: utmParams?.utm_campaign || queryParams.get('utm_campaign'),
      trustpilotState: utmParams?.trustpilotState || queryParams.get('trustpilotState'),
    };
  }

  return utmParams;
};

function* initialize() {
  try {
    yield put(actions.setGoogleAuthenticationEnabled(window.self === window.top && getResponseType() !== 'token'));
  } catch (e) {
    yield call(logError, e);
  }
}

export default function* () {
  yield all([
    takeLatest(matchesType(actions.authenticateWithGoogle), authenticateWithGoogle),
    takeLatest(matchesType(actions.authenticate), authenticate),
    takeLatest(matchesType(actions.onClickSignup), onClickSignup),
    takeLatest(matchesType(actions.initialize), initialize),
  ]);
}
