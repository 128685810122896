import * as actions from '../../actions/resetLostDevice';
import { connect, ConnectedProps } from 'react-redux';
import ResetLostDevice from '../../components/reset-lost-device/resetLostDevice';

const mapDispatchToProps = {
  resetLostDevice: actions.resetLostDevice,
  initialize: actions.initialize,
};

const mapStateToProps = (state, myProps) => ({
  initialized: state.resetLostDevice.initialized,
  resetLostDeviceInformation: state.resetLostDevice.resetLostDeviceInformation,
  inProgress: state.resetLostDevice.inProgress,
  success: state.resetLostDevice.success,
  token: myProps.token,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ResetLostDeviceProps = ConnectedProps<typeof connector>;

const ResetLostDeviceContainer = connector(ResetLostDevice);

export default ResetLostDeviceContainer;
