import { createActions } from 'redux-actions';

export const {
  loginLink: {
    setAuthenticationErrorCode,
    setAuthenticationErrorMessage,
    authenticate,
    authenticateLink,
    requestEmail,
    emailSet,
    setRemoteLoginComplete,
  },
}: any = createActions({
  LOGIN_LINK: {
    SET_AUTHENTICATION_ERROR_CODE: null,
    SET_AUTHENTICATION_ERROR_MESSAGE: null,
    AUTHENTICATE: (email, link) => ({ email, link }),
    AUTHENTICATE_LINK: (link) => ({ link }),
    REQUEST_EMAIL: null,
    EMAIL_SET: null,
    SET_REMOTE_LOGIN_COMPLETE: null,
  },
});
