import axios from 'axios';
import Config from '../../../app/configuration';

export async function resetLostDevice(token: string, password: string) {
  await axios.post(`${Config.ApiUrl}/reset-lost-device`, {
    token,
    password,
  });
}

export async function getResetLostDeviceInformation(token: string) {
  const response = await axios.get(`${Config.ApiUrl}/reset-lost-device/${token}`);

  return response.data;
}
