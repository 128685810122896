import { ErrorsToSkipSentry } from '../models/authenticationError';

export function* logError(error) {
  if (!error) {
    return;
  }

  if (ErrorsToSkipSentry.some((e) => error.message.includes(e))) {
    return;
  }

  if (typeof window.Sentry !== 'undefined') {
    yield window.Sentry.captureException(error);
  }

  console.group(error.message);
  console.error(error.message);
  console.error(error);
  console.groupEnd();
}
