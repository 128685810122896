import { all, call, put, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as loginActions from '../../actions/login';
import * as actions from '../../actions/lostDevice';
import { logError } from '../../sagas/error';
import * as api from './api';
import { GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import { AuthenticationStep } from '../../models/authenticationStep';
import * as trackingActions from '../../actions/tracking';

function* submitLostDevice(action) {
  const { email, phoneNumber } = action.payload;

  try {
    yield put(actions.setInProgress(true));
    yield put(actions.setError(null));

    yield call(api.submitLostDevice, email, phoneNumber);

    yield call(trackLostDevice, email, true);
    yield put(actions.setSuccess(true));
  } catch (e) {
    yield call(trackLostDevice, email, false);
    yield put(actions.setInProgress(false));
    GlobalAlertService.critical('Error occurred while submitting lost device', 3000);

    yield call(logError, e);
  }
}

function* backToSecondFactor() {
  try {
    yield put(loginActions.setAuthenticationStep(AuthenticationStep.SECOND_FACTOR_VERIFICATION));
  } catch (e) {
    yield call(logError, e);
  }
}

function* trackLostDevice(email, success) {
  try {
    yield put(
      trackingActions.track(
        'Button Clicked',
        {
          context: 'businessauthentication-businessapp',
          action: 'Submit lost device',
          flow: 'lost-device',
          success,
        },
        email,
      ),
    );
  } catch (error) {
    yield call(logError, error);
  }
}

export default function*() {
  yield all([
    takeLatest(matchesType(actions.submitLostDevice), submitLostDevice),
    takeLatest(matchesType(actions.backToSecondFactor), backToSecondFactor),
  ]);
}
