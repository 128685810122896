import { handleActions } from 'redux-actions';
import * as Actions from '../actions/changePasswordSecondFactorFormActions';

const initialState = {
  initialized: false,
  inProgress: false,
  phoneNumber: '',
  error: '',
  resendCodeCountdown: null,
  sessionInfo: '',
};

export const changePasswordSecondFactorFormReducer = handleActions<any>(
  {
    [Actions.setInitialized]: (state, action) => {
      return { ...state, initialized: action.payload };
    },
    [Actions.setInProgress]: (state, action) => {
      return { ...state, inProgress: action.payload };
    },
    [Actions.setError]: (state, action) => {
      return { ...state, error: action.payload };
    },
    [Actions.setPhoneNumber]: (state, action) => {
      return { ...state, phoneNumber: action.payload };
    },
    [Actions.setSessionInfo]: (state, action) => {
      return { ...state, sessionInfo: action.payload };
    },
    [Actions.setResendCodeCountdown]: (state, action) => {
      return { ...state, resendCodeCountdown: action.payload };
    },
  },
  initialState,
);
