import { combineReducers } from 'redux';
import { RootState } from './state';
import { activateAccountReducer } from '../reducers/activateAccount';
import { changePasswordReducer } from '../reducers/changePassword';
import { forgotPasswordReducer } from './forgotPassword';
import { trackingReducer } from './tracking';
import { loginReducer } from './login';
import { loginFormReducer } from './loginForm';
import { secondFactorVerificationReducer } from './secondFactorVerification';
import { lostDeviceReducer } from './lostDevice';
import { resetLostDeviceReducer } from './resetLostDevice';
import { appReducer } from './app';
import { changePasswordSecondFactorFormReducer } from './changePasswordSecondFactorForm';
import { ssoLoginReducer } from './ssoLogin';
import { loginLinkReducer } from './loginLink';

export { RootState };

// NOTE: current type definition of Reducer in 'redux-actions' module
// doesn't go well with redux@4
export const createRootReducer = () => {
  return combineReducers({
    activateAccount: activateAccountReducer as any,
    forgotPassword: forgotPasswordReducer as any,
    changePassword: changePasswordReducer as any,
    login: loginReducer as any,
    loginForm: loginFormReducer as any,
    secondFactorVerification: secondFactorVerificationReducer as any,
    tracking: trackingReducer as any,
    lostDevice: lostDeviceReducer as any,
    resetLostDevice: resetLostDeviceReducer as any,
    changePasswordSecondFactorForm: changePasswordSecondFactorFormReducer as any,
    ssoLogin: ssoLoginReducer as any,
    app: appReducer as any,
    loginLink: loginLinkReducer as any,
  });
};
