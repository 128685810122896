import { handleActions } from 'redux-actions';
import * as Actions from '../actions/tracking';

const initialState = {
  initialized: false,
};

export const trackingReducer = handleActions<any>(
  {
    [Actions.initialized]: (state) => {
      return { ...state, initialized: true };
    },
  },
  initialState,
);
