// Taken from here: https://gist.github.com/ducksoupdev/bbfcf8b9cee688b97865

const POW_2_24 = Math.pow(2, 24);

const POW_2_32 = Math.pow(2, 32);

function hex(n: number): string {
  let s = '';
  let v: number;
  for (let i = 7; i >= 0; --i) {
    v = (n >>> (i << 2)) & 0xf;
    s += v.toString(16);
  }
  return s;
}

function lrot(n: number, bits: number): number {
  return (n << bits) | (n >>> (32 - bits));
}

class Uint32ArrayBigEndian {
  bytes: Uint8Array;
  constructor(length: number) {
    this.bytes = new Uint8Array(length << 2);
  }
  get(index: number): number {
    index <<= 2;
    return (
      this.bytes[index] * POW_2_24 +
      ((this.bytes[index + 1] << 16) | (this.bytes[index + 2] << 8) | this.bytes[index + 3])
    );
  }
  set(index: number, value: number) {
    const high = Math.floor(value / POW_2_24);
    const rest = value - high * POW_2_24;
    index <<= 2;
    this.bytes[index] = high;
    this.bytes[index + 1] = rest >> 16;
    this.bytes[index + 2] = (rest >> 8) & 0xff;
    this.bytes[index + 3] = rest & 0xff;
  }
}

function string2ArrayBuffer(s: string): ArrayBuffer {
  s = s.replace(/[\u0080-\u07ff]/g, function(c: string) {
    const code = c.charCodeAt(0);
    return String.fromCharCode(0xc0 | (code >> 6), 0x80 | (code & 0x3f));
  });
  s = s.replace(/[\u0080-\uffff]/g, function(c: string) {
    const code = c.charCodeAt(0);
    return String.fromCharCode(0xe0 | (code >> 12), 0x80 | ((code >> 6) & 0x3f), 0x80 | (code & 0x3f));
  });
  const n = s.length;
  const array = new Uint8Array(n);
  for (let i = 0; i < n; ++i) {
    array[i] = s.charCodeAt(i);
  }
  return array.buffer;
}

export function hash(bufferOrString: string | ArrayBuffer): string {
  let source: ArrayBuffer;
  if (bufferOrString instanceof ArrayBuffer) {
    source = <ArrayBuffer>bufferOrString;
  } else {
    source = string2ArrayBuffer(String(bufferOrString));
  }

  let h0 = 0x67452301;
  let h1 = 0xefcdab89;
  let h2 = 0x98badcfe;
  let h3 = 0x10325476;
  let h4 = 0xc3d2e1f0;
  let i: number;
  const sbytes = source.byteLength;
  const sbits = sbytes << 3;
  const minbits = sbits + 65;
  const bits = Math.ceil(minbits / 512) << 9;
  const bytes = bits >>> 3;
  const slen = bytes >>> 2;
  const s = new Uint32ArrayBigEndian(slen);
  const s8 = s.bytes;
  let j: number;
  const w = new Uint32Array(80);
  const sourceArray = new Uint8Array(source);
  for (i = 0; i < sbytes; ++i) {
    s8[i] = sourceArray[i];
  }
  s8[sbytes] = 0x80;
  s.set(slen - 2, Math.floor(sbits / POW_2_32));
  s.set(slen - 1, sbits & 0xffffffff);
  for (i = 0; i < slen; i += 16) {
    for (j = 0; j < 16; ++j) {
      w[j] = s.get(i + j);
    }
    for (; j < 80; ++j) {
      w[j] = lrot(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1);
    }
    let a = h0;
    let b = h1;
    let c = h2;
    let d = h3;
    let e = h4;
    let f: number;
    let k: number;
    let temp: number;
    for (j = 0; j < 80; ++j) {
      if (j < 20) {
        f = (b & c) | (~b & d);
        k = 0x5a827999;
      } else if (j < 40) {
        f = b ^ c ^ d;
        k = 0x6ed9eba1;
      } else if (j < 60) {
        f = (b & c) ^ (b & d) ^ (c & d);
        k = 0x8f1bbcdc;
      } else {
        f = b ^ c ^ d;
        k = 0xca62c1d6;
      }

      temp = (lrot(a, 5) + f + e + k + w[j]) & 0xffffffff;
      e = d;
      d = c;
      c = lrot(b, 30);
      b = a;
      a = temp;
    }
    h0 = (h0 + a) & 0xffffffff;
    h1 = (h1 + b) & 0xffffffff;
    h2 = (h2 + c) & 0xffffffff;
    h3 = (h3 + d) & 0xffffffff;
    h4 = (h4 + e) & 0xffffffff;
  }
  return hex(h0) + hex(h1) + hex(h2) + hex(h3) + hex(h4);
}
