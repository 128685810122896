import axios from 'axios';
import Config from '../../../app/configuration';

export async function submitLostDevice(email: string, phoneNumber: string) {
  const response = await axios.post(`${Config.ApiUrl}/lost-device`, {
    email,
    phoneNumber,
  });

  return response.data;
}
