export enum Routes {
  ACTIVATE_ACCOUNT = '/activate-account',
  CHANGE_PASSWORD = '/changePassword/:secret',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_LOST_DEVICE = '/reset-lost-device',
  DO_NOT_SELL_MY_INFO = '/do-not-sell-my-info',
  SSO_LOGIN = '/sso-login',
  LINK_LOGIN = '/link-login',
  LOGIN = '/',
}
