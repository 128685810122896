import { createActions } from 'redux-actions';

export const {
  resetLostDevice: {
    initialize,
    setInitialized,
    setResetLostDeviceInformation,
    resetLostDevice,
    setInProgress,
    setSuccess,
  },
}: any = createActions({
  RESET_LOST_DEVICE: {
    INITIALIZE: null,
    SET_INITIALIZED: null,
    SET_RESET_LOST_DEVICE_INFORMATION: null,
    RESET_LOST_DEVICE: (token, password, email) => ({ token, password, email }),
    SET_IN_PROGRESS: null,
    SET_SUCCESS: null,
  },
});
