import { all, call, delay, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/secondFactorVerification';
import * as loginActions from '../../actions/login';
import firebase from '../../firebase';
import { PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth';
import { logError } from '../../sagas/error';
import { GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import * as trackingActions from '../../actions/tracking';

let timerTask = null;

function* resolvePhoneMultiFactorAuth(resolver) {
  const phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  };

  const verificationId = yield call(
    firebase.phoneAuthProvider.verifyPhoneNumber.bind(firebase.phoneAuthProvider),
    phoneInfoOptions,
    firebase.recaptchaVerifier,
  );

  yield put(actions.setVerificationId(verificationId));
  yield put(actions.setResendCodeCountdown(60));

  if (!timerTask) {
    timerTask = yield spawn(startResendCodeCountdown);
  }
}

function* submitVerificationCode(action) {
  const { resolver, verificationId, email, queryString } = yield select((state) => state.secondFactorVerification);
  try {
    yield put(actions.setInProgress(true));
    yield put(actions.setError(null));

    const verificationCode = action.payload;

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    yield call(resolver.resolveSignIn.bind(resolver), multiFactorAssertion);

    yield put(loginActions.verifyCurrentUserAndRedirect(email, queryString, true));
  } catch (e) {
    yield call(trackSecondFactorVerificationLoginFailed, email, e.code);
    if (e.code === 'auth/invalid-verification-code') {
      yield put(actions.setError('invalidVerificationCode'));
    } else {
      GlobalAlertService.critical('Error occurred while verifying the code', 3000);
      yield logError(e);
    }

    yield put(actions.setInProgress(false));
  }
}

function* resendCode() {
  try {
    yield put(actions.setInProgress(true));
    const { resolver } = yield select((state) => state.secondFactorVerification);

    yield call(firebase.resetRecaptcha.bind(firebase));
    yield call(resolvePhoneMultiFactorAuth, resolver);
  } catch (e) {
    yield call(logError, e);
  } finally {
    yield put(actions.setInProgress(false));
  }
}

function* startResendCodeCountdown() {
  let resendCodeCountdown;

  while (true) {
    yield delay(1000);

    resendCodeCountdown = yield select((state) => state.secondFactorVerification.resendCodeCountdown);
    if (resendCodeCountdown > 0) {
      yield put(actions.setResendCodeCountdown(resendCodeCountdown - 1));
    }
  }
}

function* initialize(action) {
  const { resolver, email } = action.payload;
  try {
    yield call(trackSecondFactorVerificationLoginAttempt, email);

    yield put(actions.setResolver(resolver));

    yield call(resolvePhoneMultiFactorAuth, resolver);
    yield put(actions.setInitialized(true));
    yield;
  } catch (e) {
    yield call(trackSecondFactorVerificationLoginFailed, email, e.code);
    if (e.code === 'auth/too-many-requests') {
      GlobalAlertService.critical('Too many authentication attempts. Please try later.', 3000);
    } else {
      GlobalAlertService.critical('Error occurred while initializing second factor verification', 3000);
    }

    yield put(actions.initializationFailed());
    yield call(logError, e);
  }
}

function* trackSecondFactorVerificationLoginAttempt(email) {
  yield put(
    trackingActions.track(
      'Button Clicked',
      {
        action: '2FA login attempted',
        context: 'businessauthentication-businessapp',
        name: '2fa-enabled-login-attempt',
      },
      email,
    ),
  );
}

function* trackSecondFactorVerificationLoginFailed(email, reason) {
  yield put(
    trackingActions.track(
      'Button Clicked',
      {
        action: '2FA login failed',
        context: 'businessauthentication-businessapp',
        name: '2fa-enabled-login-failed',
        reason,
      },
      email,
    ),
  );
}

export default function*() {
  yield all([
    takeLatest(matchesType(actions.initialize), initialize),
    takeLatest(matchesType(actions.submitVerificationCode), submitVerificationCode),
    takeLatest(matchesType(actions.resendCode), resendCode),
  ]);
}
