import axios from 'axios';
import Config from '../../../app/configuration';
import { PasswordlessResponse } from 'app/models/passwordlessResponse';

export async function sendLoginLink(email: string, url: string, connectionId?: string): Promise<PasswordlessResponse> {
  const payload = connectionId ? { email, url, connectionId } : { email, url };
  const response = await axios.post(`${Config.ApiUrl}/link`, payload);

  return response.data;
}
