import { createActions } from 'redux-actions';

export const {
  login: {
    initialize,
    setInitialized,
    setLocale,
    setPendingRedirect,
    setCustomToken,
    setAuthenticationStep,
    verifyCurrentUserAndRedirect,
    signInWithGoogle,
  },
}: any = createActions({
  LOGIN: {
    INITIALIZE: null,
    SET_INITIALIZED: null,
    SET_LOCALE: null,
    SET_PENDING_REDIRECT: null,
    SET_CUSTOM_TOKEN: null,
    SET_AUTHENTICATION_STEP: null,
    VERIFY_CURRENT_USER_AND_REDIRECT: (email, queryString, isSecondFactorLogin) => ({
      email,
      queryString,
      isSecondFactorLogin,
    }),
    SIGN_IN_WITH_GOOGLE: null,
  },
});
