import { StyledHeaderContainer, StyledInputFieldWrapper } from '../../components/commonElements';
import { Text, useTranslations } from 'ilenia';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Input, Link, Typography } from '@trustpilot/businessapp-patternlibrary';

const SecondFactorCodeForm = ({
  submitVerificationCode,
  inProgress,
  phoneNumber,
  resendCode,
  resendCodeCountdown,
  error,
  lostDeviceEnabled,
  lostDevice = null,
  email = null,
  headerText = null,
  mainButtonActionText = null,
}) => {
  const [translations] = useTranslations();
  const [code, setCode] = useState('');
  const disabled = !code || code.length !== 6 || inProgress;

  const handleEnterPress = () => {
    if (!disabled) {
      submitVerificationCode(code);
    }
  };

  const resendEnabled = resendCodeCountdown < 1 && !inProgress;
  const getANewCodeText = (
    <Text
      id="secondFactorVerification.getANewCode"
      interpolations={{ COUNTDOWN: resendCodeCountdown > 0 ? `(${resendCodeCountdown})` : '' }}
    />
  );

  return (
    <>
      <StyledHeaderContainer>
        <Typography variant="heading-m">
          {headerText ? headerText : <Text id="secondFactorVerification.header" />}
        </Typography>
      </StyledHeaderContainer>
      <StyledInformationText>
        <Typography>
          <Text id="secondFactorVerification.information" interpolations={{ PHONENUMBER: phoneNumber }} />
        </Typography>
      </StyledInformationText>
      <StyledInputFieldWrapper marginBottom={24}>
        <Input
          id="email"
          value={code}
          onChange={(evt) => setCode(evt.target.value)}
          onEnterPress={handleEnterPress}
          placeholder={translations['secondFactorVerification.codeInputPlaceholder']}
          errorMessage={error && translations[`secondFactorVerification.errors.${error}`]}
          disabled={inProgress}
        />
      </StyledInputFieldWrapper>
      <Button appearance="primary" stretch disabled={disabled} onClick={() => submitVerificationCode(code)}>
        {mainButtonActionText ? mainButtonActionText : <Text id="secondFactorVerification.logIn" />}
      </Button>
      <LinksContainer>
        {resendEnabled && (
          <Link onClick={resendCode} appearance="primary">
            {getANewCodeText}
          </Link>
        )}
        {!resendEnabled && (
          <Typography variant="body-m" appearance="disabled">
            {getANewCodeText}
          </Typography>
        )}
        {lostDeviceEnabled && (
          <Link onClick={() => lostDevice(email)}>
            <Text id="secondFactorVerification.cantAccessPhone" />
          </Link>
        )}
      </LinksContainer>
    </>
  );
};

const StyledInformationText = styled.div({
  marginBottom: 32,
  lineHeight: '24px',
});

const LinksContainer = styled.div({
  display: 'flex',
  marginTop: 40,
  justifyContent: 'center',
  fontSize: 14,
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
});

export default SecondFactorCodeForm;
