import SecondFactorVerification from '../../components/second-factor-verification/secondFactorVerification';
import * as actions from '../../actions/secondFactorVerification';
import * as lostDeviceActions from '../../actions/lostDevice';
import { connect, ConnectedProps } from 'react-redux';

const mapDispatchToProps = {
  submitVerificationCode: actions.submitVerificationCode,
  resendCode: actions.resendCode,
  lostDevice: lostDeviceActions.initialize,
};

const mapStateToProps = (state) => ({
  email: state.secondFactorVerification.email,
  phoneNumber: state.secondFactorVerification.phoneNumber,
  initialized: state.secondFactorVerification.initialized,
  error: state.secondFactorVerification.error,
  inProgress: state.secondFactorVerification.inProgress,
  resendCodeCountdown: state.secondFactorVerification.resendCodeCountdown,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SecondFactorVerificationProps = ConnectedProps<typeof connector>;

const SecondFactorVerificationContainer = connector(SecondFactorVerification);

export default SecondFactorVerificationContainer;
