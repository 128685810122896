import { handleActions } from 'redux-actions';
import * as actions from '../actions/lostDevice';

const initialState = {
  success: false,
  inProgress: false,
  email: '',
};

export const lostDeviceReducer = handleActions<any>(
  {
    [actions.initialize]: (state, action) => {
      const { email } = action.payload;

      return { ...state, email };
    },
    [actions.setInProgress]: (state, action) => {
      return { ...state, inProgress: action.payload };
    },
    [actions.setSuccess]: (state, action) => {
      return { ...state, success: action.payload };
    },
    [actions.reset]: () => initialState,
  },
  initialState,
);
