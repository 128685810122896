import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/activateAccountActions';
import * as qs from 'qs';
import { AccountActivationRequest } from '../../models/accountActivationRequest';
import * as Api from './api';
import * as commonApi from '../commonApi';
import { AccountActivationResponse } from '../../models/accountActivationResponse';
import { GlobalAlertService } from '@trustpilot/businessapp-patternlibrary';
import { logError } from '../../sagas/error';
import { ActivationState } from '../../models/activationState';
import { signInWithEmailAndPassword } from '../../sagas/login-form/loginForm';

function* activateAccount() {
  try {
    yield put(actions.setInProgress(true));
    const { password, activationSecret, activationSource, name } = yield select((state) => state.activateAccount);
    const search = yield select((state) => state.activateAccount.queryString);
    if (!activationSecret) {
      throw 'activationSecret is missing';
    }
    if (!activationSource) {
      throw 'activationSource missing';
    }
    const activationResult = yield call(Api.activateAccount, activationSecret, {
      activationSource,
      password,
      name,
    } as AccountActivationRequest);
    if (activationResult.success) {
      const email = yield select((state) => state.activateAccount.businessUser.email);
      const modifiedSearch = setDefaultBusinessAppUriParameters(search, activationResult);
      yield signInWithEmailAndPassword(email, password, modifiedSearch);
    }
  } catch (error) {
    yield put(actions.setInProgress(false));
    GlobalAlertService.critical('Error occurred while activating your account', 3000);
    yield call(logError, error);
  }
}

function setDefaultBusinessAppUriParameters(search: string, activationResponse: AccountActivationResponse): string {
  const businessAppRedirectUri = getBusinessAppRedirectUri(activationResponse);
  const parsedSearchString = qs.parse(search);

  delete parsedSearchString.activationSecret;
  delete parsedSearchString.activationSource;
  // delete version parameter
  delete parsedSearchString.v;

  parsedSearchString.redirect_uri = businessAppRedirectUri;
  parsedSearchString.locale = activationResponse.locale;
  parsedSearchString.response_type = 'code';
  parsedSearchString.cookie_domain = activationResponse.cookieDomain;
  parsedSearchString.client_id = activationResponse.businessAppClientId;

  return qs.stringify(parsedSearchString);
}

function getBusinessAppRedirectUri(activationResponse: AccountActivationResponse) {
  return activationResponse.businessAppDefaultUrl + '/?path=&locale=' + activationResponse.locale;
}

function* verify() {
  try {
    const query = window.location.search.substr(1);
    const { activationSecret, activationSource } = qs.parse(query);

    if (!activationSecret || !activationSource) {
      yield put(actions.setActivationState(ActivationState.EXPIRED));
      return;
    }

    const { state, businessUser } = yield call(Api.verify, activationSecret, activationSource);

    switch (state) {
      case ActivationState.ACTIVATED:
        yield put(
          actions.setActivationState(
            businessUser.isSsoUser ? ActivationState.SSO_ACTIVATED : ActivationState.ACTIVATED,
          ),
        );
        break;
      case ActivationState.OUTSTANDING:
        yield put(
          actions.verified({
            activationSecret,
            activationSource,
            businessUser,
            queryString: query,
          }),
        );
        yield put(actions.setActivationState(ActivationState.OUTSTANDING));
        break;
      default:
        yield put(actions.setActivationState(ActivationState.EXPIRED));
        break;
    }
  } catch (error) {
    GlobalAlertService.critical('Error occurred while verifying your account activation', 3000);
    yield call(logError, error);
  }
}

function* initialize() {
  try {
    const query = window.location.search.substr(1);
    const localeParameter = qs.parse(query).locale as string;

    const locale = yield call(commonApi.getLocale, localeParameter);
    yield put(actions.setLocale(locale));

    yield put(actions.setInitialized(true));
  } catch (error) {
    GlobalAlertService.critical('Error occurred while initializing account activation', 3000);
    yield call(logError, error);
  }
}

export default function*() {
  yield all([
    takeLatest(matchesType(actions.initialize), initialize),
    takeLatest(matchesType(actions.activateAccount), activateAccount),
    takeLatest(matchesType(actions.verify), verify),
  ]);
}
