import axios from 'axios';
import Config from '../../../app/configuration';
import { AccountActivationResponse } from '../../models/accountActivationResponse';

export async function activateAccount(activationSecret, payload): Promise<AccountActivationResponse> {
  const response = await axios.post(
    `${Config.ApiUrl}/activate-account/${activationSecret}?activationSecret=${activationSecret}&v=2`,
    payload,
  );
  return response.data;
}

export async function verify(activationSecret, activationSource) {
  const response = await axios.get(
    `${Config.ApiUrl}/activate-account/verify/${activationSecret}?activationSource=${activationSource}&v=2`,
  );
  return response.data;
}
