import React from 'react';
import { StyledCard, StyledHeader } from '../../components/commonElements';
import { Text, useTranslations } from 'ilenia';
import styled from '@emotion/styled';
import { Button } from '@trustpilot/businessapp-patternlibrary';

const ResetLostDeviceSuccess: React.FC = () => {
  const [translations] = useTranslations();

  return (
    <StyledCard>
      <StyledHeader>
        <Text id="resetLostDeviceSuccess.header" />
      </StyledHeader>
      <StyledInformationText>
        <span
          dangerouslySetInnerHTML={{
            __html: translations['resetLostDeviceSuccess.information'],
          }}
         />
      </StyledInformationText>
      <Button appearance="primary" stretch onClick={() => (window.location.href = '/')} disabled={false}>
        <Text id="resetLostDeviceSuccess.logIn" />
      </Button>
    </StyledCard>
  );
};

const StyledInformationText = styled.div({
  marginBottom: 32,
  fontSize: 16,
  lineHeight: '24px',
});

export default ResetLostDeviceSuccess;
