import { connect, ConnectedProps } from 'react-redux';
import * as actions from '../../actions/forgotPassword';
import { ForgotPasswordComponent } from './forgotPasswordComponent';

const mapDispatchToProps = {
  ...actions,
};

const mapStateToProps = (state) => ({
  ...state.forgotPassword,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ForgotPasswordComponentProps = ConnectedProps<typeof connector>;

const ForgotPasswordContainer = connector(ForgotPasswordComponent);

export default ForgotPasswordContainer;
