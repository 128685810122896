import * as actions from '../../actions/changePasswordSecondFactorFormActions';
import { connect, ConnectedProps } from 'react-redux';
import ChangePasswordSecondFactorForm from './changePasswordSecondFactorForm';

const mapDispatchToProps = {
  initialize: actions.initialize,
  submitVerificationCode: actions.submitVerificationCode,
  resendCode: actions.resendCode,
  reset: actions.reset,
};

const mapStateToProps = (state, myProps) => ({
  initialized: state.changePasswordSecondFactorForm.initialized,
  inProgress: state.changePasswordSecondFactorForm.inProgress,
  error: state.changePasswordSecondFactorForm.error,
  resendCodeCountdown: state.changePasswordSecondFactorForm.resendCodeCountdown,
  phoneNumber: myProps.phoneNumber,
  headerText: myProps.headerText,
  mainButtonActionText: myProps.mainButtonActionText,
  secret: myProps.secret,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ChangePasswordSecondFactorFormProps = ConnectedProps<typeof connector>;

const ChangePasswordSecondFactorFormContainer = connector(ChangePasswordSecondFactorForm);

export default ChangePasswordSecondFactorFormContainer;
