import flat from 'flat';
import * as H from 'history';
import qs from 'qs';

import daDK from './da-DK/strings.json';
import deDE from './de-DE/strings.json';
import enGB from './en-GB/strings.json';
import enUS from './en-US/strings.json';
import esES from './es-ES/strings.json';
import esVE from './es-VE/strings.json';
import fiFI from './fi-FI/strings.json';
import frFR from './fr-FR/strings.json';
import itIT from './it-IT/strings.json';
import nbNO from './nb-NO/strings.json';
import nlNL from './nl-NL/strings.json';
import plPL from './pl-PL/strings.json';
import ptBR from './pt-BR/strings.json';
import ptPT from './pt-PT/strings.json';
import ruRU from './ru-RU/strings.json';
import svSE from './sv-SE/strings.json';
import zhCN from './zh-CN/strings.json';

const defaultLocale = 'en-US';

const translations = {
  'da-DK': flat(daDK),
  'de-DE': flat(deDE),
  'en-GB': flat(enGB),
  'en-US': flat(enUS),
  'es-ES': flat(esES),
  'es-VE': flat(esVE),
  'fi-FI': flat(fiFI),
  'fr-FR': flat(frFR),
  'it-IT': flat(itIT),
  'nb-NO': flat(nbNO),
  'nl-NL': flat(nlNL),
  'pl-PL': flat(plPL),
  'pt-BR': flat(ptBR),
  'pt-PT': flat(ptPT),
  'ru-RU': flat(ruRU),
  'sv-SE': flat(svSE),
  'zh-CN': flat(zhCN),
};

export const getLocale = (location: H.Location<any>) => {
  if (!location.search) {
    return defaultLocale;
  }

  const queryParameters = qs.parse(location.search.substr(1));
  const locale = queryParameters.locale as string;

  if (locale && translations[locale]) {
    return locale;
  }

  return defaultLocale;
};

export const getTranslations = (locale) => {
  const fallbackTranslations = translations[defaultLocale];

  if (!translations[locale]) {
    return fallbackTranslations;
  }

  return { ...fallbackTranslations, ...translations[locale] };
};
