export const escapedHtml = (html: string) => {
  const specialCharacters = {
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&apos;',
    '/': '&sol;',
    '=': '&equals;',
    '`': '&grave;',
  };

  /* eslint-disable-next-line */
  return html.replace(/[<>"'`=\/]/g, function(s) {
    return specialCharacters[s];
  });
};
