import Config from '../configuration';
import { dispatchAuthenticationResponseEvent } from './customEvents';

export const connect = async () =>
  new Promise<string>((resolve) => {
    const socket = new WebSocket(Config.SocketUrl);
    socket.onopen = () => {
      socket.send(JSON.stringify({ action: 'connected' }));
    };

    socket.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      if ('connectionId' in eventData) {
        resolve(eventData.connectionId);
        return;
      }

      if (eventData.type === 'authentication-response') {
        dispatchAuthenticationResponseEvent(eventData.authenticationResponse);
      }
    };
  });
