import * as React from 'react';
import { useEffect } from 'react';
import { Card, NarrowPage } from '@trustpilot/businessapp-patternlibrary';
import { useTranslations } from 'ilenia';
import styled from '@emotion/styled';
import { ChangePasswordProps } from './changePasswordContainer';
import HeaderComponent from './../../header/headerComponent';
import { useRouteMatch } from 'react-router';
import Loading from './../../loading';
import ChangePasswordSuccess from './../../change-password-success/changePasswordSuccess';
import ChangePasswordForm from './../../change-password-form/changePasswordForm';
import ChangePasswordSecondFactorFormContainer from './../../change-password-second-factor-form/changePasswordSecondFactorFormContainer';
import ChangePasswordExpired from './../../change-password-expired/changePasswordExpired';

export const ChangePassword: React.FC<ChangePasswordProps> = ({
  initialize,
  initialized,
  changePasswordData,
  businessWebApp,
  inProgress,
  password,
  setPassword,
  changePassword,
  errorCode,
  success,
  secondFactorToken,
}) => {
  const [translations] = useTranslations();
  const match = useRouteMatch<{ secret: string }>();

  useEffect(() => {
    if (!initialized) {
      initialize(match.params.secret);
    }

    document.title = translations['changePassword.title'];
  }, [initialized, match]);

  let component = <Loading />;

  if (success) {
    component = <ChangePasswordSuccess businessWebApp={businessWebApp} />;
  } else if (initialized && changePasswordData && changePasswordData.phoneNumber && !secondFactorToken) {
    component = (
      <ChangePasswordSecondFactorFormContainer
        phoneNumber={changePasswordData.phoneNumber}
        headerText={translations['changePassword.2faHeaderText']}
        mainButtonActionText={translations['changePassword.2faMainButtonText']}
        secret={match.params.secret}
      />
    );
  } else if (initialized && changePasswordData) {
    component = (
      <ChangePasswordForm
        setPassword={setPassword}
        changePasswordData={changePasswordData}
        password={password}
        errorCode={errorCode}
        changePassword={changePassword}
        inProgress={inProgress}
      />
    );
  } else if (initialized && !changePasswordData) {
    component = <ChangePasswordExpired businessWebApp={businessWebApp} />;
  }

  return (
    <React.Fragment>
      <HeaderComponent />
      <StyledNarrowPage>
        <StyledContainer>
          <Card>{component}</Card>
        </StyledContainer>
      </StyledNarrowPage>
    </React.Fragment>
  );
};

const StyledContainer = styled.div({
  marginTop: 48,
  width: '100%',
  ' > div': {
    padding: '60px 80px',
  },
});
const StyledNarrowPage = styled(NarrowPage)({
  maxWidth: '560px',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 48,
});
