import { handleActions } from 'redux-actions';
import * as actions from '../actions/secondFactorVerification';

const initialState = {
  initialized: false,
  resolver: null,
  email: '',
  queryString: '',
  verificationId: null,
  recaptchaVerifierInitialized: false,
  inProgress: false,
  error: '',
  resendCodeCountdown: 60,
};

export const secondFactorVerificationReducer = handleActions<any>(
  {
    [actions.initialize]: (state, action) => {
      const { email, queryString, phoneNumber } = action.payload;

      return { ...state, email, queryString, phoneNumber };
    },
    [actions.setResolver]: (state, action) => {
      return { ...state, resolver: action.payload };
    },
    [actions.setVerificationId]: (state, action) => {
      return { ...state, verificationId: action.payload };
    },
    [actions.setInitialized]: (state, action) => {
      return { ...state, initialized: action.payload };
    },
    [actions.setInProgress]: (state, action) => {
      return { ...state, inProgress: action.payload };
    },
    [actions.setError]: (state, action) => {
      return { ...state, error: action.payload };
    },
    [actions.setResendCodeCountdown]: (state, action) => {
      return { ...state, resendCodeCountdown: action.payload };
    },
  },
  initialState,
);
