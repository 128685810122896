import styled from '@emotion/styled';
import { useTranslations } from 'ilenia';
import * as React from 'react';
import trim from 'lodash.trim';
import { CompleteActivationProps } from '../../components/complete-activation/completeActivationContainer';
import Loading from '../../components/loading';
import { LinkStyles } from '../../components/link/linkComponent';
import { StyledCard, StyledDescription, StyledHeader, StyledInputFieldWrapper } from '../commonElements';
import { CookieConsent } from '@trustpilot/businessapp-browserstorage';
import { Button, Input, Link, Typography } from '@trustpilot/businessapp-patternlibrary';

export const CompleteActivationComponent: React.FC<CompleteActivationProps> = ({
  businessUser,
  termsAndConditionsAccepted,
  allRulesMatch,
  name,
  password,
  legalUrl,
  setPassword,
  setName,
  activateAccount,
  showPassword,
  acceptTermsAndConditions,
  passwordVisible,
  characterCountRule,
  lowercaseCharacterRule,
  uppercaseCharacterRule,
  containsNumberCharacterRule,
  track,
  activationInProgress,
}) => {
  const [translations] = useTranslations();
  const submitDisabled = !allRulesMatch || !termsAndConditionsAccepted || (!businessUser?.name && !trim(name));
  const termsAndConditionsLink = `<a href="${legalUrl}/for-businesses/terms-of-use-and-sale-for-businesses" target="_blank">${translations['completeActivation.termsAndConditions.termsLinkText']}</a>`;
  const termsAndConditions = translations['completeActivation.termsAndConditions.link'].replace(
    '[TermsLink]',
    termsAndConditionsLink,
  );

  React.useEffect(() => {
    // Fire once consent given but allow time for analytics to load
    CookieConsent.onceConsentGiven('C0002', () =>
      setTimeout(
        () =>
          track('AcceptTermsAndSetPasswordView', {
            Created: businessUser.created,
            Country: businessUser.country,
            Locale: businessUser.locale,
          }),
        300,
      ),
    );
  }, []);

  return (
    <StyledCard>
      <StyledHeader>{translations['completeActivation.setPassword']}</StyledHeader>
      <StyledDescription marginBottom={20}>
        <Typography>{translations['completeActivation.completeAccountSetup']}</Typography>
      </StyledDescription>
      {!businessUser?.name && (
        <React.Fragment>
          <Input
            placeholder={translations['completeActivation.fullNamePlaceholder']}
            onChange={(event) => setName(event.target.value)}
            value={name}
            type="text"
          />
        </React.Fragment>
      )}

      <StyledInputFieldWrapper marginBottom={8}>
        <StyledShowPasswordButtonWrapper>
          <Link onClick={() => showPassword(!passwordVisible)}>
            {passwordVisible ? translations['common.hidePassword'] : translations['common.showPassword']}
          </Link>
        </StyledShowPasswordButtonWrapper>
        <Input
          id="password"
          placeholder={translations['common.password']}
          onChange={(event) => setPassword(event.target.value)}
          value={password}
          type={passwordVisible ? 'text' : 'password'}
        />
      </StyledInputFieldWrapper>
      <StyledPasswordRulesWrapper>
        <React.Fragment>
          <StyledPasswordRuleCheckBox type="checkbox" id="password-length" disabled />
          <StyledPasswordRuleLabel checked={characterCountRule} htmlFor={'password-length'}>
            {translations['completeActivation.passwordRules.atLeast8Chars']}
          </StyledPasswordRuleLabel>
        </React.Fragment>
        <React.Fragment>
          <StyledPasswordRuleCheckBox type="checkbox" id="password-length" disabled />
          <StyledPasswordRuleLabel checked={lowercaseCharacterRule} htmlFor={'password-length'}>
            {translations['completeActivation.passwordRules.oneLowercaseChar']}
          </StyledPasswordRuleLabel>
        </React.Fragment>
        <React.Fragment>
          <StyledPasswordRuleCheckBox type="checkbox" id="password-length" disabled />
          <StyledPasswordRuleLabel checked={uppercaseCharacterRule} htmlFor={'password-length'}>
            {translations['completeActivation.passwordRules.oneUppercaseChar']}
          </StyledPasswordRuleLabel>
        </React.Fragment>
        <React.Fragment>
          <StyledPasswordRuleCheckBox type="checkbox" id="password-length" disabled />
          <StyledPasswordRuleLabel checked={containsNumberCharacterRule} htmlFor={'password-length'}>
            {translations['completeActivation.passwordRules.oneNumber']}
          </StyledPasswordRuleLabel>
        </React.Fragment>
        <div>
          <StyledTermsAndConditionsCheckBox
            type="checkbox"
            id="acceptTermsAndConditions"
            onClick={() => acceptTermsAndConditions(!termsAndConditionsAccepted)}
          />
          <StyledTermsAndConditionsLabel
            checked={termsAndConditionsAccepted}
            htmlFor={'acceptTermsAndConditions'}
            dangerouslySetInnerHTML={{ __html: termsAndConditions }}
          />
        </div>
      </StyledPasswordRulesWrapper>
      {activationInProgress && <Loading />}
      {!activationInProgress && (
        <Button
          data-testid="activateButton"
          stretch
          appearance="primary"
          onClick={activateAccount}
          disabled={submitDisabled}
        >
          {translations['completeActivation.completeSetup']}
        </Button>
      )}
    </StyledCard>
  );
};

const StyledPasswordRulesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const StyledShowPasswordButtonWrapper = styled.span({
  float: 'right',
  marginBottom: '6px',
  fontSize: '14px',
});

const StyledPasswordRuleCheckBox = styled.input({
  display: 'none',
});

const StyledTermsAndConditionsCheckBox = styled.input({
  display: 'none',
});

type StyledTermsAndConditionsLabelProps = {
  checked: boolean;
};

const StyledTermsAndConditionsLabel = styled.label<StyledTermsAndConditionsLabelProps>(
  {
    '&::before': {
      position: 'relative',
      top: 5,
      content: '""',
      display: 'inline-block',
      height: 20,
      width: 20,
      border: '1px solid #CDD3DB',
      borderRadius: 3,
      margin: '0 8px 0 -28px',
      boxSizing: 'border-box',
    },
    display: 'inline-block',
    cursor: 'pointer',
    margin: '16px 0 32px 28px',
    fontSize: 14,
    color: '#4d4d4d',
    lineHeight: 1.5,
    ' > a': LinkStyles,
  },
  (props: StyledTermsAndConditionsLabelProps) => ({
    '&::before': {
      background: props.checked
        ? 'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCAxMiAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5CbHVlIGNoZWNrbWFyazwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYXNzd29yZC1zaWRlIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPiAgICAgICAgPGcgaWQ9IkNUQS1hY3RpdmF0ZWQtQ29weS0yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTI0LjAwMDAwMCwgLTUxNC4wMDAwMDApIiBzdHJva2U9IiMwQzU5RjIiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgPGcgaWQ9IkFjY2VwdC10ZXJtcy13aXRoLWNoZWNrbWFyay0iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUyMC4wMDAwMDAsIDUxMC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJCbHVlLWNoZWNrbWFyayIgcG9pbnRzPSIxNSA1IDcuODI2MDg2OTYgMTUgNSAxMi4xNzM5MTMiPjwvcG9seWxpbmU+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+) no-repeat 45% 50%'
        : '#FFF',
    },
  }),
);

type PaswordRuleLabelProps = {
  checked: boolean;
};
const StyledPasswordRuleLabel = styled.label<PaswordRuleLabelProps>(
  {
    '&::before': {
      position: 'relative',
      top: 4,
      cursor: 'default',
      content: '""',
      display: 'inline-block',
      height: 17,
      width: 17,
      border: '1px solid #DFE2E5',
      borderRadius: 10,
      marginRight: 9,
      boxSizing: 'border-box',
    },
    display: 'inline-block',
    cursor: 'default',
    marginBottom: 12,
    fontSize: 14,
    color: '#4d4d4d',
    lineHeight: 1.5,
  },
  (props: PaswordRuleLabelProps) => ({
    '&::before': {
      background: props.checked
        ? 'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI4cHgiIGhlaWdodD0iOHB4IiB2aWV3Qm94PSIwIDAgOCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPndoaXRlIGNoZWNrbWFyayA8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFzc3dvcmQtc2lkZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJDVEEtYWN0aXZhdGVkLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUyNC4wMDAwMDAsIC00NTguMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0id2hpdGUtY2hlY2ttYXJrLSIgcG9pbnRzPSI1MzEgNDU5IDUyNi42OTU2NTIgNDY1IDUyNSA0NjMuMzA0MzQ4Ij48L3BvbHlsaW5lPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+) #00B67A no-repeat 45% 50%'
        : 'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI4cHgiIGhlaWdodD0iOHB4IiB2aWV3Qm94PSIwIDAgOCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPndoaXRlIGNoZWNrbWFyayA8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFzc3dvcmQtc2lkZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJDVEEtYWN0aXZhdGVkLUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUyNC4wMDAwMDAsIC00NTguMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0id2hpdGUtY2hlY2ttYXJrLSIgcG9pbnRzPSI1MzEgNDU5IDUyNi42OTU2NTIgNDY1IDUyNSA0NjMuMzA0MzQ4Ij48L3BvbHlsaW5lPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+) #DFE2E5 no-repeat 45% 50%',
    },
  }),
);
