import * as actions from '../../actions/loginLink';
import { connect, ConnectedProps } from 'react-redux';
import { LoginForm } from './linkLoginForm';

const mapDispatchToProps = {
  authenticate: actions.authenticate,
};

const mapStateToProps = (state) => ({
  emailRequired: state.loginLink.emailRequired,
  authenticationErrorCode: state.loginLink.authenticationErrorCode,
  authenticationErrorMessage: state.loginLink.authenticationErrorMessage,
  remoteLoginComplete: state.loginLink.remoteLoginComplete,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LinkLoginProps = ConnectedProps<typeof connector>;

const LinkLoginFormContainer = connector(LoginForm);

export default LinkLoginFormContainer;
