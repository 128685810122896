import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSignInEmailFromLocalStorage, matchesType, removeSignInEmailFromLocalStorage } from '../helpers';
import * as actions from '../../actions/loginLink';
import * as loginActions from '../../actions/login';
import * as secondFactorVerificationActions from '../../actions/secondFactorVerification';
import firebase from '../../firebase';
import { logError } from '../../sagas/error';
import { AuthenticationError } from '../../models/authenticationError';

function* authenticate(action) {
  const { email, link } = action.payload;
  const query = window.location.search.substring(1);

  try {
    yield put(actions.emailSet());

    const isValid = yield call(firebase.isSignInWithEmailLink, link);
    if (!isValid) {
      yield put(actions.setAuthenticationErrorCode(AuthenticationError.INVALID_SIGNIN_LINK));
      return;
    }

    yield call(firebase.signInWithEmailLink, email, link);
    removeSignInEmailFromLocalStorage();
    yield put(loginActions.verifyCurrentUserAndRedirect(email, query));
  } catch (error) {
    switch (error.code) {
      case 'auth/expired-action-code':
        yield put(actions.setAuthenticationErrorCode(AuthenticationError.EXPIRED_SIGNIN_LINK));
        break;
      case 'auth/invalid-action-code':
        yield put(actions.setAuthenticationErrorCode(AuthenticationError.INVALID_SIGNIN_LINK));
        break;
      case 'auth/invalid-email':
        yield put(actions.setAuthenticationErrorCode(AuthenticationError.INVALID_SIGNIN_EMAIL));
        break;
      case 'auth/multi-factor-auth-required':
        const query = window.location.search.substr(1);

        const resolver = firebase.getMultiFactorResolver(error);
        const {
          customData: {
            _serverResponse: { mfaInfo },
          },
        } = error;
        yield put(secondFactorVerificationActions.initialize(resolver, email, query, mfaInfo[0].phoneInfo));
        break;
      default:
        yield put(actions.setAuthenticationErrorMessage(error.message));
        yield call(logError, error);
    }
  }
}

function* authenticateLink(action) {
  try {
    const email = getSignInEmailFromLocalStorage();
    if (!email) {
      yield put(actions.requestEmail());
      return;
    }

    const { link } = action.payload;
    yield call(authenticate, { payload: { email, link } });
  } catch (e) {
    yield call(logError, e);
  }
}

export default function* () {
  yield all([takeLatest(matchesType(actions.authenticateLink), authenticateLink)]);
  yield all([takeLatest(matchesType(actions.authenticate), authenticate)]);
}
