import { createActions } from 'redux-actions';

export const {
  app: { initialize, setInitialized, setLocale },
}: any = createActions({
  APP: {
    INITIALIZE: null,
    SET_INITIALIZED: null,
    SET_LOCALE: null,
  },
});
