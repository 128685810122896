import { connect, ConnectedProps } from 'react-redux';
import { ResetLostDevice } from './resetLostDevice';
import { withRouter } from 'react-router';
import * as qs from 'qs';

const mapStateToProps = (state, myProps) => {
  const { location } = myProps;
  const { token } = qs.parse(location.search.substr(1));

  return {
    token,
  };
};

const connector = connect(mapStateToProps);

export type ResetLostDeviceProps = ConnectedProps<typeof connector>;

const ResetLostDeviceContainer = withRouter(connector(ResetLostDevice));

export default ResetLostDeviceContainer;
