import * as actions from '../../actions/activateAccountActions';
import { connect, ConnectedProps } from 'react-redux';
import { CompleteActivationComponent } from '../../components/complete-activation/completeActivationComponent';
import * as tracking from '../../actions/tracking';

const mapDispatchToProps = {
  ...actions,
  ...tracking,
};

const mapStateToProps = (state) => ({
  ...state.activateAccount,
  legalUrl: state.activateAccount.locale?.legalUrl,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CompleteActivationProps = ConnectedProps<typeof connector>;

const CompleteActivationContainer = connector(CompleteActivationComponent);

export default CompleteActivationContainer;
