import { handleActions } from 'redux-actions';
import * as Actions from '../actions/login';
import { AuthenticationStep } from '../models/authenticationStep';

const initialState = {
  initialized: false,
  pendingRedirect: false,
  authenticationStep: AuthenticationStep.LOGIN,
  locale: null,
  customToken: null,
};

export const loginReducer = handleActions<any>(
  {
    [Actions.setInitialized]: (state, action) => {
      return { ...state, initialized: action.payload };
    },
    [Actions.setPendingRedirect]: (state, action) => {
      return { ...state, pendingRedirect: action.payload };
    },
    [Actions.setAuthenticationStep]: (state, action) => {
      return { ...state, authenticationStep: action.payload };
    },
    [Actions.setLocale]: (state, action) => {
      return { ...state, locale: action.payload };
    },
    [Actions.setCustomToken]: (state, action) => {
      return { ...state, customToken: action.payload };
    },
  },
  initialState,
);
