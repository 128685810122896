import * as React from 'react';
import { useEffect } from 'react';
import { NarrowPage } from '@trustpilot/businessapp-patternlibrary';
import HeaderComponent from '../../../components/header/headerComponent';
import SsoLoginComponentContainer from '../../../components/sso-login/ssoLoginComponentContainer';
import { useTranslations } from 'ilenia';
import { AuthenticationStep } from '../../../models/authenticationStep';
import { StyledCardContainer } from '../../../components/commonElements';
import { SsoLoginProps } from './ssoLoginContainer';
import LostDeviceContainer from '../../../components/lost-device/lostDeviceContainer';
import SecondFactorVerificationContainer from '../../../components/second-factor-verification/secondFactorVerificationContainer';
import firebase from '../../../firebase';

export const SsoLogin: React.FC<SsoLoginProps> = ({ authenticationStep }) => {
  const [translations] = useTranslations();
  const [ref, setRef] = React.useState(null);

  useEffect(() => {
    if (ref) {
      firebase.initRecaptchaVerifier();
    }
  }, [ref]);

  useEffect(() => {
    document.title = translations['login.title'];
  }, []);

  let Component: React.ElementType = SsoLoginComponentContainer;

  if (authenticationStep === AuthenticationStep.SECOND_FACTOR_VERIFICATION) {
    Component = SecondFactorVerificationContainer;
  } else if (authenticationStep === AuthenticationStep.LOST_DEVICE) {
    Component = LostDeviceContainer;
  }

  return (
    <React.Fragment>
      <HeaderComponent />
      <NarrowPage>
        <StyledCardContainer>
          <Component />
        </StyledCardContainer>
      </NarrowPage>
      <div id="recaptcha-container" ref={(ref) => setRef(ref)} />
    </React.Fragment>
  );
};
