import { handleActions } from 'redux-actions';
import * as Actions from '../actions/ssoLoginActions';

function isSSOEnforcedRedirection() {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectionReason = urlParams.get('redirectionReason');
  if (redirectionReason && decodeURIComponent(redirectionReason) === 'SSO login required') {
    return true;
  }
  return false;
}

const initialState = {
  initialized: false,
  inProgress: false,
  success: false,
  domain: '',
  loginProvider: '',
  errorCode: null,
  isSSOEnforcedRedirection: isSSOEnforcedRedirection(),
};

export const ssoLoginReducer = handleActions<any>(
  {
    [Actions.setInitialized]: (state, action) => {
      return { ...state, initialized: action.payload };
    },
    [Actions.setDomain]: (state, action) => {
      return { ...state, domain: action.payload };
    },
    [Actions.setLoginProvider]: (state, action) => {
      return { ...state, loginProvider: action.payload };
    },
    [Actions.login]: (state) => {
      return { ...state, inProgress: true };
    },
    [Actions.setInProgress]: (state, action) => {
      return { ...state, inProgress: action.payload };
    },
    [Actions.setSuccess]: (state) => {
      return { ...state, inProgress: false, success: true };
    },
    [Actions.setFailed]: (state, action) => {
      return { ...state, inProgress: false, errorCode: action.payload };
    },
  },
  initialState,
);
