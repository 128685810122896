import * as React from 'react';
import { useEffect } from 'react';
import { Button, GlobalAlertService, Link, NarrowPage } from '@trustpilot/businessapp-patternlibrary';
import HeaderComponent from '../../../components/header/headerComponent';
import { Text, useTranslations } from 'ilenia';
import { StyledCard, StyledCardContainer, StyledDescription, StyledHeader } from '../../commonElements';
import { CookieConsent } from '@trustpilot/businessapp-browserstorage';
import styled from '@emotion/styled';

export const DoNotSellMyInfoContainer: React.FC = () => {
  const [translations] = useTranslations();

  const onClick = React.useCallback(() => {
    CookieConsent.showCookiesPreferences().catch(() => {
      GlobalAlertService.critical(translations['cookieConsent.error'], 3000);
    });
  }, []);

  useEffect(() => {
    document.title = translations['cookieConsent.doNotSellMyInfoPage.header'];
  }, []);

  return (
    <React.Fragment>
      <HeaderComponent />
      <NarrowPage>
        <StyledCardContainer>
          <StyledCard>
            <StyledHeader>
              <Text id="cookieConsent.doNotSellMyInfoPage.header" />
            </StyledHeader>
            <StyledDescription marginBottom={16}>
              {translations['cookieConsent.doNotSellMyInfoPage.paragraph1']}
            </StyledDescription>
            <StyledDescription marginBottom={32}>
              <Text
                id="cookieConsent.doNotSellMyInfoPage.paragraph2"
                interpolations={{
                  LINK: (content) => (
                    <Link href="https://legal.trustpilot.com/for-businesses/business-privacy-policy" target="_blank">
                      {content}
                    </Link>
                  ),
                }}
                suffix={{ begin: '-BEGIN', end: '-END' }}
              />
            </StyledDescription>
            <StyledButtonContainer>
              <Button appearance="primary" stretch onClick={onClick} disabled={false}>
                <Text id="cookieConsent.doNotSellMyInfoPage.buttonLabel" />
              </Button>
            </StyledButtonContainer>
          </StyledCard>
        </StyledCardContainer>
      </NarrowPage>
    </React.Fragment>
  );
};

const StyledButtonContainer = styled.div({
  marginBottom: '16px',
});
