import * as actions from '../../../actions/changePasswordActions';
import { connect, ConnectedProps } from 'react-redux';
import { ChangePassword } from './changePassword';

const mapDispatchToProps = {
  initialize: actions.initialize,
  setPassword: actions.setPassword,
  changePassword: actions.changePassword,
};

const mapStateToProps = (state) => ({
  initialized: state.changePassword.initialized,
  password: state.changePassword.password,
  inProgress: state.changePassword.inProgress,
  success: state.changePassword.success,
  errorCode: state.changePassword.errorCode,
  changePasswordData: state.changePassword.changePasswordData,
  secondFactorToken: state.changePassword.secondFactorToken,
  businessWebApp: state.changePassword.locale?.businessWebApp,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ChangePasswordProps = ConnectedProps<typeof connector>;

const ChangePasswordContainer = connector(ChangePassword);

export default ChangePasswordContainer;
