import * as React from 'react';
import styled from '@emotion/styled';
import { Spinner } from '@trustpilot/businessapp-patternlibrary';

const Loading = () => {
  return (
    <StyledCenteredContainer>
      <Spinner size={36} />
    </StyledCenteredContainer>
  );
};

const StyledCenteredContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export default Loading;
