export const validatePassword = (password) => {
  const validation8Characters = password.length >= 8;
  const validationLowercaseCharacter = /[a-z]/.test(password);
  const validationUppercaseCharacter = /[A-Z]/.test(password);
  const validationOneNumber = /\d/.test(password);

  return {
    validation8Characters,
    validationLowercaseCharacter,
    validationUppercaseCharacter,
    validationOneNumber,
    valid: validation8Characters && validationLowercaseCharacter && validationUppercaseCharacter && validationOneNumber,
  };
};
