import { handleActions } from 'redux-actions';
import * as Actions from '../actions/loginLink';

const initialState = {
  authenticationErrorCode: null,
  authenticationErrorMessage: null,
  emailRequired: false,
  remoteLoginComplete: false,
};

export const loginLinkReducer = handleActions<any>(
  {
    [Actions.setAuthenticationErrorCode]: (state, action) => {
      return { ...state, authenticationErrorCode: action.payload };
    },
    [Actions.setAuthenticationErrorMessage]: (state, action) => {
      return { ...state, authenticationErrorMessage: action.payload };
    },
    [Actions.requestEmail]: (state) => {
      return { ...state, email: '', emailRequired: true };
    },
    [Actions.emailSet]: (state) => {
      return { ...state, emailRequired: false };
    },
    [Actions.setRemoteLoginComplete]: (state) => {
      return { ...state, remoteLoginComplete: true };
    },
  },
  initialState,
);
