export enum AuthenticationError {
  GOOGLE_CANNOT_AUTHORIZE = 1000,
  INVALID_CREDENTIALS = 1001,
  INCOMPLETE_ACTIVATION = 1002,
  INVALID_REQUEST = 1003,
  UNSUPPORTED_RESPONSE_TYPE = 1004,
  GOOGLE_UNEXPECTED_ERROR = 1005,
  INACTIVE_USER = 1006,
  USER_NOT_FOUND = 1007,
  GOOGLE_USER_NOT_FOUND = 1101,
  CANNOT_AUTHORIZE = 1008,
  UNEXPECTED_ERROR = 1010,
  SSO_CONFIGURATION_NOT_FOUND = 1100,
  SSO_USER_NOT_FOUND = 1102,
}

export const AuthenticationErrorMessage = {
  [AuthenticationError.GOOGLE_CANNOT_AUTHORIZE]: 'login.error.googleCannotAuthorize',
  [AuthenticationError.CANNOT_AUTHORIZE]: 'login.error.cannotAuthorize',
  [AuthenticationError.INVALID_CREDENTIALS]: 'login.error.invalidCredentials',
  [AuthenticationError.INCOMPLETE_ACTIVATION]: 'login.error.incompleteActivation',
  [AuthenticationError.INACTIVE_USER]: 'login.error.inactiveUser',
  [AuthenticationError.USER_NOT_FOUND]: 'login.error.userNotFound',
  [AuthenticationError.GOOGLE_USER_NOT_FOUND]: 'login.error.googleUserNotFound',
  [AuthenticationError.INVALID_REQUEST]: 'login.error.invalidRequest',
  [AuthenticationError.UNSUPPORTED_RESPONSE_TYPE]: 'login.error.unsupportedResponseType',
  [AuthenticationError.GOOGLE_UNEXPECTED_ERROR]: 'login.error.googleUnexpectedError',
  [AuthenticationError.UNEXPECTED_ERROR]: 'login.error.unexpectedError',
  [AuthenticationError.SSO_CONFIGURATION_NOT_FOUND]: 'login.error.ssoConfigurationNotFound',
  [AuthenticationError.SSO_USER_NOT_FOUND]: 'login.error.ssoUserNotFound',
};

export const ErrorsToSkipSentry = [
  'This account isn’t active.\nThis could be because you didn’t finish signing up, or your access was removed.\nTo continue, check your inbox for an activation email, or contact your Trustpilot Business account admin.\n',
  'You have not completed the activation process, so please check your inbox for the activation email. Or your user was deactivated by the administrator.',
  'The user account has been disabled by an administrator.',
  'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
  "The user's credential is no longer valid. The user must sign in again.",
];
