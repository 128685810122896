import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { matchesType } from '../helpers';
import * as actions from '../../actions/tracking';
import { AppAnalytics } from './appAnalytics';
import { logError } from '../error';

let analyticsPromise: Promise<AppAnalytics> = null;

function* init() {
  try {
    analyticsPromise = new AppAnalytics().loadTrackingScript();
    yield analyticsPromise;
    yield put(actions.initialized());
  } catch (e) {
    yield call(logError, e);
  }
}

function* identify(action) {
  try {
    const analytics = yield analyticsPromise;
    yield call(analytics.identify, action.payload);
  } catch (e) {
    yield call(logError, e);
  }
}

function* track(action) {
  try {
    const analytics = yield analyticsPromise;
    const email = action.payload.email;
    if (email) {
      yield call(analytics.identify, email);
    }
    yield call(analytics.track, action.payload.eventName, action.payload.eventData);
  } catch (e) {
    yield call(logError, e);
  }
}

function* trackAndNavigate(action) {
  try {
    yield call(track, action);

    const { url, target } = action.payload;

    if (target === '_blank') {
      window.open(url, '_blank');
    } else {
      window.location.assign(url);
    }
  } catch (e) {
    yield call(logError, e);
  }
}

function* trackLoginAttempt(action) {
  const { email, responseType, provider, loginSuccess, redirectUrl, providerId, target } = action.payload;

  const eventName = 'Login Attempted';

  const eventData = {
    ResponseType: responseType,
    Provider: provider,
    LoginSuccess: loginSuccess,
    ProviderId: providerId,
  };

  const payload = { eventName, eventData, email };

  yield call(track, { payload });

  //Resetting analytics events once move away from the auth page
  if (window.analytics) {
    window.analytics.reset();
  }

  //Resetting amplitude events once move away from the auth page
  if (window['amplitude']) {
    window['amplitude'].regenerateDeviceId();
    window['amplitude'].setUserId(null);
  }

  if (redirectUrl) {
    if (target === '_blank') {
      window.open(redirectUrl, '_blank');
    } else {
      window.location.assign(redirectUrl);
    }
  }
}

export default function* () {
  yield all([
    takeLatest(matchesType(actions.init), init),
    takeEvery(matchesType(actions.identify), identify),
    takeEvery(matchesType(actions.track), track),
    takeEvery(matchesType(actions.trackAndNavigate), trackAndNavigate),
    takeEvery(matchesType(actions.trackLoginAttempt), trackLoginAttempt),
  ]);
}
